import React, { Children } from 'react'

//@ts-ignore
import './typographyStyles.scss'

interface Props {
    children: React.ReactNode;
    smaller?: boolean;
    moreSmaller?: boolean;
    medium?: boolean;
    centered?: boolean;
    className?: any;
    style?: any;
}

export default function TitleComponent(props: Props) {
    const { children, smaller, medium, centered, moreSmaller, style, className } = props;

    return (
        <p className={`title 
        ${medium ? 'title-medium' : ''}
        ${centered ? 'title-centered' : ''}
        ${smaller ? 'title-smaller' : ''}
        ${moreSmaller ? 'title-moreSmaller' : ''}
        ${className ? className : ''}`}
            style={style}>
            {children}
        </p>
    )
}

