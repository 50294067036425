const categories = {
  id: '28',
  entity: 1,
  validateFieldsErrors: [],
  import_key: null,
  array_options: [],
  array_languages: null,
  contacts_ids: null,
  linked_objects: null,
  linkedObjectsIds: null,
  linkedObjectsFullLoaded: [],
  fk_projet: null,
  origin: null,
  origin_id: null,
  ref: null,
  ref_ext: null,
  status: null,
  state_id: null,
  region_id: null,
  demand_reason_id: null,
  transport_mode_id: null,
  model_pdf: null,
  last_main_doc: null,
  fk_bank: null,
  note_public: null,
  note_private: null,
  date_creation: 1657545435,
  date_validation: null,
  date_modification: 1657545435,
  date_cloture: null,
  user_author: null,
  user_creation: 2,
  user_creation_id: 2,
  user_valid: null,
  user_validation: null,
  user_validation_id: null,
  user_closing_id: null,
  user_modification: 0,
  user_modification_id: 0,
  specimen: 0,
  fk_parent: 0,
  label: 'Electro-technical',
  description: '',
  color: 'ffd4aa',
  visible: 0,
  type: 0,
  childs: [
    {
      id: '29',
      entity: 1,
      validateFieldsErrors: [],
      import_key: null,
      array_options: [],
      array_languages: null,
      contacts_ids: null,
      linked_objects: null,
      linkedObjectsIds: null,
      linkedObjectsFullLoaded: [],
      fk_projet: null,
      origin: null,
      origin_id: null,
      ref: null,
      ref_ext: null,
      status: null,
      state_id: null,
      region_id: null,
      demand_reason_id: null,
      transport_mode_id: null,
      model_pdf: null,
      last_main_doc: null,
      fk_bank: null,
      note_public: null,
      note_private: null,
      date_creation: 1657545466,
      date_validation: null,
      date_modification: 1657545466,
      date_cloture: null,
      user_author: null,
      user_creation: 2,
      user_creation_id: 2,
      user_valid: null,
      user_validation: null,
      user_validation_id: null,
      user_closing_id: null,
      user_modification: 0,
      user_modification_id: 0,
      specimen: 0,
      fk_parent: 28,
      label: 'Surge protection',
      description: '',
      color: 'ffd4aa',
      visible: 0,
      type: 0,
      childs: [],
    },
    {
      id: '35',
      entity: 1,
      validateFieldsErrors: [],
      import_key: null,
      array_options: [],
      array_languages: null,
      contacts_ids: null,
      linked_objects: null,
      linkedObjectsIds: null,
      linkedObjectsFullLoaded: [],
      fk_projet: null,
      origin: null,
      origin_id: null,
      ref: null,
      ref_ext: null,
      status: null,
      state_id: null,
      region_id: null,
      demand_reason_id: null,
      transport_mode_id: null,
      model_pdf: null,
      last_main_doc: null,
      fk_bank: null,
      note_public: null,
      note_private: null,
      date_creation: 1657545893,
      date_validation: null,
      date_modification: 1657545893,
      date_cloture: null,
      user_author: null,
      user_creation: 2,
      user_creation_id: 2,
      user_valid: null,
      user_validation: null,
      user_validation_id: null,
      user_closing_id: null,
      user_modification: 0,
      user_modification_id: 0,
      specimen: 0,
      fk_parent: 28,
      label: 'Switch',
      description: '',
      color: 'ffd4aa',
      visible: 0,
      type: 0,
      childs: [],
    },
    {
      id: '38',
      entity: 1,
      validateFieldsErrors: [],
      import_key: null,
      array_options: [],
      array_languages: null,
      contacts_ids: null,
      linked_objects: null,
      linkedObjectsIds: null,
      linkedObjectsFullLoaded: [],
      fk_projet: null,
      origin: null,
      origin_id: null,
      ref: null,
      ref_ext: null,
      status: null,
      state_id: null,
      region_id: null,
      demand_reason_id: null,
      transport_mode_id: null,
      model_pdf: null,
      last_main_doc: null,
      fk_bank: null,
      note_public: null,
      note_private: null,
      date_creation: 1657545966,
      date_validation: null,
      date_modification: 1657545966,
      date_cloture: null,
      user_author: null,
      user_creation: 2,
      user_creation_id: 2,
      user_valid: null,
      user_validation: null,
      user_validation_id: null,
      user_closing_id: null,
      user_modification: 0,
      user_modification_id: 0,
      specimen: 0,
      fk_parent: 28,
      label: 'Communication input',
      description: '',
      color: 'ffd4aa',
      visible: 0,
      type: 0,
      childs: [],
    },
    {
      id: '58',
      entity: 1,
      validateFieldsErrors: [],
      import_key: null,
      array_options: [],
      array_languages: null,
      contacts_ids: null,
      linked_objects: null,
      linkedObjectsIds: null,
      linkedObjectsFullLoaded: [],
      fk_projet: null,
      origin: null,
      origin_id: null,
      ref: null,
      ref_ext: null,
      status: null,
      state_id: null,
      region_id: null,
      demand_reason_id: null,
      transport_mode_id: null,
      model_pdf: null,
      last_main_doc: null,
      fk_bank: null,
      note_public: null,
      note_private: null,
      date_creation: 1659012714,
      date_validation: null,
      date_modification: 1659012714,
      date_cloture: null,
      user_author: null,
      user_creation: 2,
      user_creation_id: 2,
      user_valid: null,
      user_validation: null,
      user_validation_id: null,
      user_closing_id: null,
      user_modification: 0,
      user_modification_id: 0,
      specimen: 0,
      fk_parent: 28,
      label: 'Smart energy meter',
      description: '',
      color: 'ffd4aa',
      visible: 0,
      type: 0,
      childs: [],
    },
  ],
};
 export default categories;