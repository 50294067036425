import { Helmet } from 'react-helmet';
import './references.style.scss'
import { ContainerComponent, ImageSwiper, ParagraphComponent, SubtitleComponent, TitleComponent } from '../../components';
import { Trans, useTranslation } from 'react-i18next';

//@ts-ignore
import a1 from '../../assets/images/references/projekt2/a1.jpg';
//@ts-ignore
import a2 from '../../assets/images/references/projekt2/a2.jpg';
//@ts-ignore
import a3 from '../../assets/images/references/projekt2/a3.JPG';
//@ts-ignore
import a4 from '../../assets/images/references/projekt2/a4.png';
//@ts-ignore
import a5 from '../../assets/images/references/projekt2/a5.JPG';
//@ts-ignore
import a6 from '../../assets/images/references/projekt2/a6.JPG';
//@ts-ignore
import a7 from '../../assets/images/references/projekt2/a7.jpg';
//@ts-ignore
import a8 from '../../assets/images/references/projekt2/a8.jpg';


function ReferenceABS() {
    const { t, i18n } = useTranslation();

    return (
        <>
            <div className="referencePlinara" >
                <Helmet>
                    <title>{t('project2.meta.title')}</title>
                    <meta name="description" content={t('project2.meta.description')} />
                </Helmet>
                <ContainerComponent>
                    <Trans>
                        <h1><TitleComponent centered smaller style={{ marginTop: '3rem' }}>{t('project2.title')}</TitleComponent></h1>
                        <h3><SubtitleComponent>{t('project2.subtitle')}</SubtitleComponent></h3>
                        
                        <div className="specifications">
                            <h3><SubtitleComponent medium white large semiBold>{t('project2.specifications.title')}</SubtitleComponent></h3>
                            <ParagraphComponent>{t('project2.specifications.content')}</ParagraphComponent>
                        </div>
                        <ImageSwiper images={[a1, a2, a3, a4, a5, a6, a7, a8]} thumbs />
                    </Trans>
                </ContainerComponent>
            </div>
        </>
    )
}

export default ReferenceABS