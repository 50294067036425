import { useState } from 'react'
import { ButtonComponent, ContainerComponent, ProductCardComponent } from '../../components'
import './electro.style.scss'
import Data from "./data";
import categories from './categories';
import TitleComponent from '../../components/typography/title.component';
import { withTranslation } from 'react-i18next';
import { Filter } from '../chargers/filters';

interface data {
  id: string,
  title?: string,
  category?: string,
  brand?: string,
  desc?: string,
  url?: string,
  specifications?: Filter,
  label?: string
}

function Electro({ t }: { t: any }) {

  const [activeCategory, setActiveCategory] = useState('');

  let menuItems: { [key: string]: string } = {};

  Array.from(new Set((categories.childs).map((val) => { menuItems[val.id] = val.label }
  )));
  let filteredData: Array<data>;


  let tempArray: any = [];
  Data.map((val, i) => {
    let tempObj: { id: string, label: string } = { id: '', label: '' }

    tempObj.id = val.id; tempObj.label = val.label;
    tempArray[i] = tempObj;

  }


  )

  filteredData = tempArray;




  const [product, setProduct] = useState(Data);
  // let url = window.location.href.substring(window.location.href.indexOf('#') + 1);

  // 


  // const filterItem = (curcat: string) => {
  //     const newItem = Data.filter((newVal) => {
  //         return newVal.category === curcat;
  //     });
  //     setProduct(newItem);
  // };

  return (
    <div className="equipment" id="equipmentContainer">
      <ContainerComponent>
        <TitleComponent centered smaller style={{ marginTop: '3rem' }}>{t('electroEquipment.title')}</TitleComponent>
        <div className="categoryContainer">
          <ButtonComponent
            key={0}
            white={activeCategory !== '' ? true : false}
            style={{ border: 0 }}
            // categoryId={id.toString()}
            categoryButton={activeCategory === '' ? true : false}
            onClick={() => { setActiveCategory(''); setProduct(Data) }
            }
          >
            Sve
          </ButtonComponent>
          {Object.entries(menuItems).map((val) => {
            let id = val[0];
            let name = val[1];
            return (
              <ButtonComponent
                wide
                key={id}
                white={activeCategory !== id ? true : false}
                style={{ border: 0 }}
                categoryId={id}
                categoryButton={activeCategory === id.toString() ? true : false}
                onClick={(e: any) => {
                  setActiveCategory(e.target.id);
                  //  filterItem(val) 
                }
                }
              >
                {name}
              </ButtonComponent>)
          })}
        </div>
        <div className="productCardContainer">
          {/* <ProductCardComponent electro data={filteredData} /> */}
        </div>
      </ContainerComponent>
    </div>
  )
}

export default withTranslation()(Electro);

