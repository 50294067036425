import React, { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { FreeMode, Navigation, Thumbs, type Swiper as SwiperType } from 'swiper';

import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';
import './imageSwiper.style.scss'
import { ParagraphComponent } from '../typography';
import { Trans } from 'react-i18next';

interface Caption {
    [key: string]: string;
}

// Define props for the reusable component
interface ImageSwiperProps {
    images: string[];  // Array of image URLs
    thumbs?: boolean;  // Whether to use thumbs
    className?: string; // Custom class name
    captions?: Caption;
}

export const ImageSwiper: React.FC<ImageSwiperProps> = ({ images, thumbs = false, className, captions }) => {
    const [thumbsSwiper, setThumbsSwiper] = useState<SwiperType | null>(null);

    return (
        <div className='imageSwiper'>
            <Trans>
                <Swiper
                    navigation={true}
                    thumbs={thumbs ? { swiper: thumbsSwiper } : undefined}
                    modules={[FreeMode, Navigation, Thumbs]}
                    pagination={{ type: "progressbar" }}
                    className={className || "mySwiper"}
                >
                    {images.map((src, index) => (
                        <SwiperSlide key={index}><img src={src} alt={`Slide ${index + 1}`} />
                            {captions &&
                                <div className="caption">
                                    <h4>{captions[index]}</h4>
                                </div>}
                        </SwiperSlide>
                    ))}

                </Swiper>
                {thumbs && (
                    <Swiper
                        onSwiper={setThumbsSwiper}
                        spaceBetween={10}
                        slidesPerView={5}
                        freeMode={true}
                        watchSlidesProgress={true}
                        modules={[FreeMode, Navigation, Thumbs]}
                        className={className || "mySwiper"}
                    >
                        {images.map((src, index) => (
                            <SwiperSlide key={index}><img src={src} alt={`Thumb ${index + 1}`} /></SwiperSlide>
                        ))}
                    </Swiper>
                )}
            </Trans>
        </div>
    );
};
