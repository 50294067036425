import { ParagraphComponent, SubtitleComponent } from '../typography'

function ProductCardSkeletonComponent() {
    return (
        <div className="skeletonCard">
            <div className="productCard">
                <div className={`imgBackground`}></div>
                <div className="productCardContent">
                    <div className="productCardHeader">
                        <SubtitleComponent className='productTitle' white>Lorem Ipsum Dolor Sit Amet Consectetur</SubtitleComponent>
                        <ParagraphComponent className='productBrand' >Lorem ipsum - dolor sit</ParagraphComponent>
                    </div>
                    <div className="productCardFooter">
                    <ParagraphComponent className='productBrand' >Lorem ipsum - dolor sit dolor sit dolor sit</ParagraphComponent>
                        <div className="shimmer-wrapper">
                            <div className="shimmer"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProductCardSkeletonComponent