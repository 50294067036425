import React from 'react'
import { ContainerComponent, SubtitleComponent, TitleComponent } from '../../components'
import './about.style.scss'
import { Trans, withTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

function About({ t }: { t: any }) {
  return (
    <div className='about'>
      <Helmet>
        <title>{t('aboutUs.meta.title')}</title>
        <meta name="description" content={t('aboutUs.meta.description')} />
      </Helmet>
      <div className="text">
        <ContainerComponent>
          <Trans>
            <TitleComponent smaller centered>{t('aboutUs.title')}</TitleComponent>
            <SubtitleComponent> {t('aboutUs.subtitle1')}<a href="https://www.transeast.hr/"> TRANSEAST </a>{t('aboutUs.subtitle2')}</SubtitleComponent>
          </Trans>
        </ContainerComponent>

      </div>
    </div>
  )
}

export default withTranslation()(About);
