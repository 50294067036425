import './card.styles.scss';

interface Props {
    children: React.ReactNode;
    centered?: boolean;
    className?: any;
    style?: any
}

export default function CardComponent(props: Props) {

    const { children, centered, className, style } = props;

    return (
        <div
        className={` card 
        ${className}
        ${centered && 'card-centered'}`}
            style={style}>{children}</div>
    )
}