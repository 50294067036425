import { useEffect } from 'react';
import './container.style.scss'
interface Props {
  children: React.ReactNode
}
export default function ContainerComponent(props: Props) {

  const { children } = props;

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className='container'>
      {children}
    </div>
  )
}