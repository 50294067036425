import React, { useState } from 'react'
import { ModalComponent, ButtonComponent, ParagraphComponent, TitleComponent, SubtitleComponent } from '../';
import { HashLink } from 'react-router-hash-link';
import { Trans, withTranslation } from 'react-i18next';

import './header.style.scss'

//@ts-ignore
import arrow from '../../assets/icons/Vector.svg'

interface Props {
    home: boolean;
    t: any;
}

function HeaderComponent(props: Props) {
    const { home, t } = props;
    const [openModal, setOpenModal] = useState(false)


    return (
        <div className='header'>
            <ModalComponent openModal={openModal} closeModal={() => setOpenModal(false)} />
            <div className='header__text'>
                <Trans>
                    <h1>
                        <TitleComponent className='header__text__title'>{t('home.header.title')}</TitleComponent>
                    </h1>
                </Trans>
                <SubtitleComponent> {t('home.header.subtitle')}</SubtitleComponent>
                <div className="buttonsContainer">
                    <a>
                        <ButtonComponent wide className="headerButton" onClick={() => setOpenModal(true)} green> {t('home.header.button')}</ButtonComponent></a>
                    <HashLink smooth to="#solarItems">
                        <ButtonComponent wide className="headerButton" categoryId="learnMore" >{t('misc.learnMore')} <img style={{ marginLeft: 15 }} src={arrow} alt="Arrow Icon" /></ButtonComponent>
                    </HashLink>

                </div>

            </div>


        </div>
    )
}

export default withTranslation()(HeaderComponent);

