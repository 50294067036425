import { ParagraphComponent, SubtitleComponent } from '../../components'
function ProductPageSkeletonComponent() {
    return (
        <div className="skeletonProduct">
            <div className='imgBackground'></div>
            <div className="productCardContent">
                <div className="productCardHeader">
                    {/* <ParagraphComponent className='productBrand' >Lorem ipsum - dolor sit</ParagraphComponent> */}

                    <SubtitleComponent className='productTitle'>Lorem Ipsum Dolor Sit Amet Consectetur</SubtitleComponent>
                    <ParagraphComponent className='productBrand' >Lorem ipsum - dolor sit</ParagraphComponent>
                    <div className="shimmer-wrapper">
                        <div className="shimmer"></div>
                    </div>
                </div>
                <div className="productCardFooter">
                    <ParagraphComponent className='productBrand' >Lorem ipsum - dolor sit dolor sit dolor sit</ParagraphComponent>
                    <ParagraphComponent className='productBrand' >Lorem ipsum - dolor sit dolor sit dolor sit</ParagraphComponent>
                    <SubtitleComponent className='productPrice'>Lorem Ipsum Dolor </SubtitleComponent>

                    {/* <div className="shimmer-wrapper">
                            <div className="shimmer"></div>
                        </div> */}
                </div>
            </div>
        </div>
    )
}

export default ProductPageSkeletonComponent