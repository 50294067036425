import { Helmet } from 'react-helmet'
import { ButtonComponent, ContainerComponent, ProductCardComponent, ProductCardSkeletonComponent, SubtitleComponent, TitleComponent } from '../../../components'
import { Trans, useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react';
import { Product } from '../../../components/productCard/data';
import { sortProductsByStock } from '../../equipment/Equipment';
import { decode } from 'html-entities';
import useProductStore from '../../../store/productStore';

function EvAccessories() {
    const { t, i18n } = useTranslation();

    const { charger_accessories, loading, fetchChargerAccessories } = useProductStore();
    const [currentPage, setCurrentPage] = useState(1)
    const [tempAccessories, setTempAccessories] = useState<Product[]>([]);

    useEffect(() => {
        if (charger_accessories.products.length === 0) fetchChargerAccessories(0);
    }, [])

    useEffect(() => {
        const sortedProducts = sortProductsByStock(charger_accessories.products);
        setTempAccessories(sortedProducts);
    }, [charger_accessories]);

    return (
        <div className='chargers'>
            <Helmet>
                <title>{t('chargersEquipment.title2')}</title>
                <meta name="description" content={t('chargersEquipment.meta.description')} />
            </Helmet>
            <ContainerComponent>
                <Trans>
                    <h1><TitleComponent smaller centered>{t('chargersEquipment.title2')}</TitleComponent></h1>
                    <SubtitleComponent centered>{t('solarEquipment.subtitle')}</SubtitleComponent>
                </Trans>
                {loading ?
                    <div className="productCardContainer">
                        <ProductCardSkeletonComponent />
                        <ProductCardSkeletonComponent />
                        <ProductCardSkeletonComponent />
                        <ProductCardSkeletonComponent />
                        <ProductCardSkeletonComponent />
                        <ProductCardSkeletonComponent />
                    </div> : <>
                        {charger_accessories.pagination.total ? <p style={{ color: 'white' }}>{tempAccessories.length} of {charger_accessories.pagination.total} Products</p> : ''}
                        <div className="productCardContainer">
                            <ProductCardComponent data={tempAccessories} />
                        </div>
                    </>}
                {charger_accessories.pagination.page_count && currentPage <= charger_accessories.pagination.page_count - 1 ?
                    <ButtonComponent style={{margin: '0 auto', display: 'block'}} onClick={() => { setCurrentPage(currentPage + 1); fetchChargerAccessories(currentPage) }}>Load more</ButtonComponent>
                    : ''}
            </ContainerComponent>
        </div>
    )

}

export default EvAccessories