// http://192.168.0.74/dolibarr/api/index.php/products?sortfield=t.ref&sortorder=ASC&limit=100&category=28
const Data = [
  {
    id: '10',
    entity: '1',
    validateFieldsErrors: [],
    import_key: null,
    array_options: {
      options_brand: null,
      options_title: null,
    },
    array_languages: null,
    contacts_ids: null,
    linked_objects: null,
    linkedObjectsIds: null,
    linkedObjectsFullLoaded: [],
    canvas: '',
    fk_projet: null,
    ref: '1010',
    ref_ext: null,
    status: '1',
    country_id: null,
    country_code: '',
    state_id: null,
    region_id: null,
    barcode_type: null,
    barcode_type_coder: null,
    last_main_doc: null,
    note_public: null,
    note_private: '',
    total_ht: null,
    total_tva: null,
    total_localtax1: null,
    total_localtax2: null,
    total_ttc: null,
    date_creation: '2022-07-07 17:25:08',
    date_validation: null,
    date_modification: '2022-07-29 14:49:57.181988',
    date_cloture: null,
    user_author: null,
    user_creation: null,
    user_creation_id: null,
    user_valid: null,
    user_validation: null,
    user_validation_id: null,
    user_closing_id: null,
    user_modification: null,
    user_modification_id: null,
    specimen: 0,
    label: 'Geya - DCB 1000V 4p 63A',
    description: '',
    other: null,
    type: '0',
    price: '0.00000000',
    price_ttc: '0.00000000',
    price_min: '0.00000000',
    price_min_ttc: '0.00000000',
    price_base_type: 'HT',
    multiprices: [],
    multiprices_ttc: [],
    multiprices_base_type: [],
    multiprices_min: [],
    multiprices_min_ttc: [],
    multiprices_tva_tx: [],
    prices_by_qty: [],
    prices_by_qty_list: [],
    multilangs: {
      en_US: {
        label: 'Geya - DCB 1000V 4p 63A',
        description: '',
        other: null,
      },
    },
    default_vat_code: null,
    tva_tx: '25.0000',
    localtax1_tx: '0.0000',
    localtax2_tx: '0.0000',
    localtax1_type: '0',
    localtax2_type: '0',
    lifetime: null,
    qc_frequency: null,
    stock_reel: '50',
    stock_theorique: null,
    cost_price: null,
    pmp: '16.00000000',
    seuil_stock_alerte: '0',
    desiredstock: '0',
    duration_value: '',
    duration_unit: '',
    status_buy: '1',
    finished: null,
    fk_default_bom: null,
    status_batch: '0',
    batch_mask: '',
    customcode: '',
    url: null,
    weight: null,
    weight_units: '0',
    length: null,
    length_units: '0',
    width: null,
    width_units: '0',
    height: null,
    height_units: '0',
    surface: null,
    surface_units: '0',
    volume: null,
    volume_units: '0',
    net_measure: null,
    net_measure_units: null,
    accountancy_code_sell: '',
    accountancy_code_sell_intra: '',
    accountancy_code_sell_export: '',
    accountancy_code_buy: '',
    accountancy_code_buy_intra: '',
    accountancy_code_buy_export: '',
    barcode: null,
    stock_warehouse: [],
    fk_default_warehouse: null,
    fk_price_expression: null,
    fk_unit: null,
    price_autogen: '0',
    is_object_used: null,
    mandatory_period: '0',
    duration: '',
  },
  {
    id: '12',
    entity: '1',
    validateFieldsErrors: [],
    import_key: null,
    array_options: {
      options_brand: null,
      options_title: null,
    },
    array_languages: null,
    contacts_ids: null,
    linked_objects: null,
    linkedObjectsIds: null,
    linkedObjectsFullLoaded: [],
    canvas: '',
    fk_projet: null,
    ref: '1012',
    ref_ext: null,
    status: '1',
    country_id: '9',
    country_code: 'CN',
    state_id: null,
    region_id: null,
    barcode_type: null,
    barcode_type_coder: null,
    last_main_doc: null,
    note_public: null,
    note_private: '',
    total_ht: null,
    total_tva: null,
    total_localtax1: null,
    total_localtax2: null,
    total_ttc: null,
    date_creation: '2022-07-07 17:25:45',
    date_validation: null,
    date_modification: '2022-07-29 14:50:31.466035',
    date_cloture: null,
    user_author: null,
    user_creation: null,
    user_creation_id: null,
    user_valid: null,
    user_validation: null,
    user_validation_id: null,
    user_closing_id: null,
    user_modification: null,
    user_modification_id: null,
    specimen: 0,
    label: 'Geya - AC_SPD_4P',
    description: '',
    other: null,
    type: '0',
    price: '0.00000000',
    price_ttc: '0.00000000',
    price_min: '0.00000000',
    price_min_ttc: '0.00000000',
    price_base_type: 'HT',
    multiprices: [],
    multiprices_ttc: [],
    multiprices_base_type: [],
    multiprices_min: [],
    multiprices_min_ttc: [],
    multiprices_tva_tx: [],
    prices_by_qty: [],
    prices_by_qty_list: [],
    multilangs: {
      en_US: {
        label: 'Geya - AC_SPD_4P',
        description: '',
        other: null,
      },
    },
    default_vat_code: null,
    tva_tx: '25.0000',
    localtax1_tx: '0.0000',
    localtax2_tx: '0.0000',
    localtax1_type: '0',
    localtax2_type: '0',
    lifetime: null,
    qc_frequency: null,
    stock_reel: '50',
    stock_theorique: null,
    cost_price: null,
    pmp: '25.00000000',
    seuil_stock_alerte: '0',
    desiredstock: '0',
    duration_value: '',
    duration_unit: '',
    status_buy: '1',
    finished: null,
    fk_default_bom: null,
    status_batch: '0',
    batch_mask: '',
    customcode: '',
    url: null,
    weight: null,
    weight_units: '0',
    length: null,
    length_units: '0',
    width: null,
    width_units: '0',
    height: null,
    height_units: '0',
    surface: null,
    surface_units: '0',
    volume: null,
    volume_units: '0',
    net_measure: null,
    net_measure_units: null,
    accountancy_code_sell: '',
    accountancy_code_sell_intra: '',
    accountancy_code_sell_export: '',
    accountancy_code_buy: '',
    accountancy_code_buy_intra: '',
    accountancy_code_buy_export: '',
    barcode: null,
    stock_warehouse: [],
    fk_default_warehouse: null,
    fk_price_expression: null,
    fk_unit: null,
    price_autogen: '0',
    is_object_used: null,
    mandatory_period: '0',
    duration: '',
  },
  {
    id: '9',
    entity: '1',
    validateFieldsErrors: [],
    import_key: null,
    array_options: {
      options_brand: null,
      options_title: null,
    },
    array_languages: null,
    contacts_ids: null,
    linked_objects: null,
    linkedObjectsIds: null,
    linkedObjectsFullLoaded: [],
    canvas: '',
    fk_projet: null,
    ref: '1009',
    ref_ext: null,
    status: '1',
    country_id: null,
    country_code: '',
    state_id: null,
    region_id: null,
    barcode_type: null,
    barcode_type_coder: null,
    last_main_doc: null,
    note_public: null,
    note_private: '',
    total_ht: null,
    total_tva: null,
    total_localtax1: null,
    total_localtax2: null,
    total_ttc: null,
    date_creation: '2022-07-07 17:24:41',
    date_validation: null,
    date_modification: '2022-07-29 14:48:48.401129',
    date_cloture: null,
    user_author: null,
    user_creation: null,
    user_creation_id: null,
    user_valid: null,
    user_validation: null,
    user_validation_id: null,
    user_closing_id: null,
    user_modification: null,
    user_modification_id: null,
    specimen: 0,
    label: 'Geya - DC_SPD_T2_1000V_3P',
    description: '',
    other: null,
    type: '0',
    price: '0.00000000',
    price_ttc: '0.00000000',
    price_min: '0.00000000',
    price_min_ttc: '0.00000000',
    price_base_type: 'HT',
    multiprices: [],
    multiprices_ttc: [],
    multiprices_base_type: [],
    multiprices_min: [],
    multiprices_min_ttc: [],
    multiprices_tva_tx: [],
    prices_by_qty: [],
    prices_by_qty_list: [],
    multilangs: {
      en_US: {
        label: 'Geya - DC_SPD_T2_1000V_3P',
        description: '',
        other: null,
      },
    },
    default_vat_code: null,
    tva_tx: '25.0000',
    localtax1_tx: '0.0000',
    localtax2_tx: '0.0000',
    localtax1_type: '0',
    localtax2_type: '0',
    lifetime: null,
    qc_frequency: null,
    stock_reel: '50',
    stock_theorique: null,
    cost_price: null,
    pmp: '25.00000000',
    seuil_stock_alerte: '0',
    desiredstock: '0',
    duration_value: '',
    duration_unit: '',
    status_buy: '1',
    finished: null,
    fk_default_bom: null,
    status_batch: '0',
    batch_mask: '',
    customcode: '',
    url: null,
    weight: null,
    weight_units: '0',
    length: null,
    length_units: '0',
    width: null,
    width_units: '0',
    height: null,
    height_units: '0',
    surface: null,
    surface_units: '0',
    volume: null,
    volume_units: '0',
    net_measure: null,
    net_measure_units: null,
    accountancy_code_sell: '',
    accountancy_code_sell_intra: '',
    accountancy_code_sell_export: '',
    accountancy_code_buy: '',
    accountancy_code_buy_intra: '',
    accountancy_code_buy_export: '',
    barcode: null,
    stock_warehouse: [],
    fk_default_warehouse: null,
    fk_price_expression: null,
    fk_unit: null,
    price_autogen: '0',
    is_object_used: null,
    mandatory_period: '0',
    duration: '',
  },
  {
    id: '8',
    entity: '1',
    validateFieldsErrors: [],
    import_key: null,
    array_options: {
      options_brand: null,
      options_title: null,
    },
    array_languages: null,
    contacts_ids: null,
    linked_objects: null,
    linkedObjectsIds: null,
    linkedObjectsFullLoaded: [],
    canvas: '',
    fk_projet: null,
    ref: '1008',
    ref_ext: null,
    status: '1',
    country_id: null,
    country_code: '',
    state_id: null,
    region_id: null,
    barcode_type: null,
    barcode_type_coder: null,
    last_main_doc: null,
    note_public: null,
    note_private: '',
    total_ht: null,
    total_tva: null,
    total_localtax1: null,
    total_localtax2: null,
    total_ttc: null,
    date_creation: '2022-07-07 17:24:27',
    date_validation: null,
    date_modification: '2022-07-29 13:08:43.319948',
    date_cloture: null,
    user_author: null,
    user_creation: null,
    user_creation_id: null,
    user_valid: null,
    user_validation: null,
    user_validation_id: null,
    user_closing_id: null,
    user_modification: null,
    user_modification_id: null,
    specimen: 0,
    label: 'High-Flying - HF2211S',
    description: '',
    other: null,
    type: '0',
    price: '0.00000000',
    price_ttc: '0.00000000',
    price_min: '0.00000000',
    price_min_ttc: '0.00000000',
    price_base_type: 'HT',
    multiprices: [],
    multiprices_ttc: [],
    multiprices_base_type: [],
    multiprices_min: [],
    multiprices_min_ttc: [],
    multiprices_tva_tx: [],
    prices_by_qty: [],
    prices_by_qty_list: [],
    multilangs: {
      en_US: {
        label: 'High-Flying - HF2211S',
        description: '',
        other: null,
      },
    },
    default_vat_code: null,
    tva_tx: '25.0000',
    localtax1_tx: '0.0000',
    localtax2_tx: '0.0000',
    localtax1_type: '0',
    localtax2_type: '0',
    lifetime: null,
    qc_frequency: null,
    stock_reel: '50',
    stock_theorique: null,
    cost_price: null,
    pmp: '15.00000000',
    seuil_stock_alerte: '0',
    desiredstock: '0',
    duration_value: '',
    duration_unit: '',
    status_buy: '1',
    finished: null,
    fk_default_bom: null,
    status_batch: '0',
    batch_mask: '',
    customcode: '',
    url: null,
    weight: null,
    weight_units: '0',
    length: null,
    length_units: '0',
    width: null,
    width_units: '0',
    height: null,
    height_units: '0',
    surface: null,
    surface_units: '0',
    volume: null,
    volume_units: '0',
    net_measure: null,
    net_measure_units: null,
    accountancy_code_sell: '',
    accountancy_code_sell_intra: '',
    accountancy_code_sell_export: '',
    accountancy_code_buy: '',
    accountancy_code_buy_intra: '',
    accountancy_code_buy_export: '',
    barcode: null,
    stock_warehouse: [],
    fk_default_warehouse: null,
    fk_price_expression: null,
    fk_unit: null,
    price_autogen: '0',
    is_object_used: null,
    mandatory_period: '0',
    duration: '',
  },
  {
    id: '11',
    entity: '1',
    validateFieldsErrors: [],
    import_key: null,
    array_options: {
      options_brand: null,
      options_title: null,
    },
    array_languages: null,
    contacts_ids: null,
    linked_objects: null,
    linkedObjectsIds: null,
    linkedObjectsFullLoaded: [],
    canvas: '',
    fk_projet: null,
    ref: '1011',
    ref_ext: null,
    status: '1',
    country_id: '9',
    country_code: 'CN',
    state_id: null,
    region_id: null,
    barcode_type: null,
    barcode_type_coder: null,
    last_main_doc: null,
    note_public: null,
    note_private: '',
    total_ht: null,
    total_tva: null,
    total_localtax1: null,
    total_localtax2: null,
    total_ttc: null,
    date_creation: '2022-07-07 17:25:27',
    date_validation: null,
    date_modification: '2022-07-29 14:48:17.071063',
    date_cloture: null,
    user_author: null,
    user_creation: null,
    user_creation_id: null,
    user_valid: null,
    user_validation: null,
    user_validation_id: null,
    user_closing_id: null,
    user_modification: null,
    user_modification_id: null,
    specimen: 0,
    label: 'Geya - DC isolator switch',
    description: '',
    other: null,
    type: '0',
    price: '0.00000000',
    price_ttc: '0.00000000',
    price_min: '0.00000000',
    price_min_ttc: '0.00000000',
    price_base_type: 'HT',
    multiprices: [],
    multiprices_ttc: [],
    multiprices_base_type: [],
    multiprices_min: [],
    multiprices_min_ttc: [],
    multiprices_tva_tx: [],
    prices_by_qty: [],
    prices_by_qty_list: [],
    multilangs: {
      en_US: {
        label: 'Geya - DC isolator switch',
        description: '',
        other: null,
      },
    },
    default_vat_code: null,
    tva_tx: '25.0000',
    localtax1_tx: '0.0000',
    localtax2_tx: '0.0000',
    localtax1_type: '0',
    localtax2_type: '0',
    lifetime: null,
    qc_frequency: null,
    stock_reel: '20',
    stock_theorique: null,
    cost_price: null,
    pmp: '29.00000000',
    seuil_stock_alerte: '0',
    desiredstock: '0',
    duration_value: '',
    duration_unit: '',
    status_buy: '1',
    finished: null,
    fk_default_bom: null,
    status_batch: '0',
    batch_mask: '',
    customcode: '',
    url: null,
    weight: null,
    weight_units: '0',
    length: null,
    length_units: '0',
    width: null,
    width_units: '0',
    height: null,
    height_units: '0',
    surface: null,
    surface_units: '0',
    volume: null,
    volume_units: '0',
    net_measure: null,
    net_measure_units: null,
    accountancy_code_sell: '',
    accountancy_code_sell_intra: '',
    accountancy_code_sell_export: '',
    accountancy_code_buy: '',
    accountancy_code_buy_intra: '',
    accountancy_code_buy_export: '',
    barcode: null,
    stock_warehouse: [],
    fk_default_warehouse: null,
    fk_price_expression: null,
    fk_unit: null,
    price_autogen: '0',
    is_object_used: null,
    mandatory_period: '0',
    duration: '',
  },
  {
    id: '82',
    entity: '1',
    validateFieldsErrors: [],
    import_key: null,
    array_options: {
      options_brand: null,
      options_title: null,
    },
    array_languages: null,
    contacts_ids: null,
    linked_objects: null,
    linkedObjectsIds: null,
    linkedObjectsFullLoaded: [],
    canvas: '',
    fk_projet: null,
    ref: '1079',
    ref_ext: null,
    status: '1',
    country_id: '9',
    country_code: 'CN',
    state_id: null,
    region_id: null,
    barcode_type: null,
    barcode_type_coder: null,
    last_main_doc: null,
    note_public: null,
    note_private: '',
    total_ht: null,
    total_tva: null,
    total_localtax1: null,
    total_localtax2: null,
    total_ttc: null,
    date_creation: '2022-07-29 13:05:24',
    date_validation: null,
    date_modification: '2022-07-29 13:09:42.630627',
    date_cloture: null,
    user_author: null,
    user_creation: null,
    user_creation_id: null,
    user_valid: null,
    user_validation: null,
    user_validation_id: null,
    user_closing_id: null,
    user_modification: null,
    user_modification_id: null,
    specimen: 0,
    label: 'High Flying - HF5142A',
    description: '',
    other: null,
    type: '0',
    price: '0.00000000',
    price_ttc: '0.00000000',
    price_min: '0.00000000',
    price_min_ttc: '0.00000000',
    price_base_type: 'HT',
    multiprices: [],
    multiprices_ttc: [],
    multiprices_base_type: [],
    multiprices_min: [],
    multiprices_min_ttc: [],
    multiprices_tva_tx: [],
    prices_by_qty: [],
    prices_by_qty_list: [],
    multilangs: {
      en_US: {
        label: 'High Flying - HF5142A',
        description: '',
        other: null,
      },
    },
    default_vat_code: null,
    tva_tx: '25.0000',
    localtax1_tx: '0.0000',
    localtax2_tx: '0.0000',
    localtax1_type: '0',
    localtax2_type: '0',
    lifetime: null,
    qc_frequency: null,
    stock_reel: null,
    stock_theorique: null,
    cost_price: null,
    pmp: '0.00000000',
    seuil_stock_alerte: '0',
    desiredstock: '0',
    duration_value: '',
    duration_unit: '',
    status_buy: '1',
    finished: null,
    fk_default_bom: null,
    status_batch: '0',
    batch_mask: '',
    customcode: '',
    url: null,
    weight: null,
    weight_units: '0',
    length: '0.09',
    length_units: '0',
    width: '0.2',
    width_units: '0',
    height: '0.025',
    height_units: '0',
    surface: '0.018',
    surface_units: '0',
    volume: '0.00045',
    volume_units: '0',
    net_measure: null,
    net_measure_units: null,
    accountancy_code_sell: '',
    accountancy_code_sell_intra: '',
    accountancy_code_sell_export: '',
    accountancy_code_buy: '',
    accountancy_code_buy_intra: '',
    accountancy_code_buy_export: '',
    barcode: null,
    stock_warehouse: [],
    fk_default_warehouse: null,
    fk_price_expression: null,
    fk_unit: null,
    price_autogen: '0',
    is_object_used: null,
    mandatory_period: '0',
    duration: '',
  },
  {
    id: '29',
    entity: '1',
    validateFieldsErrors: [],
    import_key: null,
    array_options: {
      options_brand: null,
      options_title: null,
    },
    array_languages: null,
    contacts_ids: null,
    linked_objects: null,
    linkedObjectsIds: null,
    linkedObjectsFullLoaded: [],
    canvas: '',
    fk_projet: null,
    ref: '1029',
    ref_ext: null,
    status: '1',
    country_id: '9',
    country_code: 'CN',
    state_id: null,
    region_id: null,
    barcode_type: null,
    barcode_type_coder: null,
    last_main_doc: null,
    note_public: null,
    note_private: '',
    total_ht: null,
    total_tva: null,
    total_localtax1: null,
    total_localtax2: null,
    total_ttc: null,
    date_creation: '2022-07-08 17:01:38',
    date_validation: null,
    date_modification: '2022-07-29 13:09:10.960877',
    date_cloture: null,
    user_author: null,
    user_creation: null,
    user_creation_id: null,
    user_valid: null,
    user_validation: null,
    user_validation_id: null,
    user_closing_id: null,
    user_modification: null,
    user_modification_id: null,
    specimen: 0,
    label: 'High Flying - HF2211A',
    description: '',
    other: null,
    type: '0',
    price: '0.00000000',
    price_ttc: '0.00000000',
    price_min: '0.00000000',
    price_min_ttc: '0.00000000',
    price_base_type: 'HT',
    multiprices: [],
    multiprices_ttc: [],
    multiprices_base_type: [],
    multiprices_min: [],
    multiprices_min_ttc: [],
    multiprices_tva_tx: [],
    prices_by_qty: [],
    prices_by_qty_list: [],
    multilangs: {
      en_US: {
        label: 'High Flying - HF2211A',
        description: '',
        other: null,
      },
    },
    default_vat_code: null,
    tva_tx: '25.0000',
    localtax1_tx: '0.0000',
    localtax2_tx: '0.0000',
    localtax1_type: '0',
    localtax2_type: '0',
    lifetime: null,
    qc_frequency: null,
    stock_reel: '20',
    stock_theorique: null,
    cost_price: null,
    pmp: '39.00000000',
    seuil_stock_alerte: '0',
    desiredstock: '0',
    duration_value: '',
    duration_unit: '',
    status_buy: '1',
    finished: null,
    fk_default_bom: null,
    status_batch: '0',
    batch_mask: '',
    customcode: '',
    url: null,
    weight: null,
    weight_units: '0',
    length: null,
    length_units: '0',
    width: null,
    width_units: '0',
    height: null,
    height_units: '0',
    surface: null,
    surface_units: '0',
    volume: null,
    volume_units: '0',
    net_measure: null,
    net_measure_units: null,
    accountancy_code_sell: '',
    accountancy_code_sell_intra: '',
    accountancy_code_sell_export: '',
    accountancy_code_buy: '',
    accountancy_code_buy_intra: '',
    accountancy_code_buy_export: '',
    barcode: null,
    stock_warehouse: [],
    fk_default_warehouse: '4',
    fk_price_expression: null,
    fk_unit: null,
    price_autogen: '0',
    is_object_used: null,
    mandatory_period: '0',
    duration: '',
  },
];

export default Data;
