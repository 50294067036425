const Data = [
  {
    id: '0',
    title: 'Solar Panel SP380MB-60H',
    category: 'solar_panels',
    brand: '',
    desc: '380W, Bifacial, Dual glass with frame',
    url: 'https://tesenergy.hr/assets/pdfs/TES_E_SP380M_60H.pdf',
    image: 'https://tesenergy.hr/assets/images/products/solarPanel.webp',
  },
  // {
  //   id: '1',
  //   title: 'Solar Carport for 42 panels',
  //   category: 'carport',
  //   brand: '',
  //   desc: 'Solar carport for SP380MB-60H 42 panels',
  //   url: '',
  //   image: 'https://www.enerack.com/uploadfile/202202/08/4d8d29120a663928f4c6d112391946b4_medium.jpg',
  // },
  {
    id: '2',
    title: 'Solar Panel SP380M-60H',
    category: 'solar_panels',
    brand: '',
    desc: '380W, Single glass With Frame',
    url: 'https://tesenergy.hr/assets/pdfs/TES_E_SP380MB_60H.pdf',
    image: 'https://tesenergy.hr/assets/images/products/solarPanel.webp',
  },
  {
    id: '3',
    title: '1X6.0mm2 H1Z2Z2-K Black CCA - 500MTR',
    category: 'cables_and_connectors',
    brand: 'Athilex',
    desc: '500m Cable, CCA',
    url: 'https://athilex.com/wp-content/uploads/2022/01/H1Z2Z2-K-Athilex-CCA.pdf',
    image: 'https://tesenergy.hr/assets/images/products/black500.webp',
  },
  {
    id: '4',
    title: '1X6.0mm2 H1Z2Z2-K Red CCA - 500MTR',
    category: 'cables_and_connectors',
    brand: 'Athilex',
    desc: '500m Cable, CCA',
    url: 'https://athilex.com/wp-content/uploads/2022/01/H1Z2Z2-K-Athilex-CCA.pdf',
    image: 'https://tesenergy.hr/assets/images/products/red500.webp',
  },
  {
    id: '5',
    title: '1X4.0mm2 H1Z2Z2-K Black DCA - 500MTR',
    category: 'cables_and_connectors',
    brand: 'Athilex',
    desc: '500m Cable, CCA',
    url: 'https://tesenergy.hr/assets/pdfs/TES_ENERGY_ATHILEX DCA.pdf',
    image: 'https://tesenergy.hr/assets/images/products/black500.webp',
  },
  {
    id: '6',
    title: '3PH Hybrid Inverter 13.3kW ',
    category: 'inverters',
    brand: 'Sungrow',
    desc: '13.3kW, 2 MPPTS, 37.5A, 3-phase, Hybrid, Type II AC & DC, W 10years, IP65',
    url: 'https://info-support.sungrowpower.com/application/pdf/2022/04/25/DS_20220321_SH5.0_6.0_8.0_10RT_Datasheet_V17_EN.pdf',
    image: 'https://en.sungrowpower.com/upload/6375781599073589155587109.png',
  },
  {
    id: '7',
    title: '1X4.0mm2 H1Z2Z2-K Black DCA - 500MTR',
    category: 'cables_and_connectors',
    brand: 'Athilex',
    desc: '500m Cable, CCA',
    url: 'https://tesenergy.hr/assets/pdfs/TES_ENERGY_ATHILEX DCA.pdf',
    image: 'https://tesenergy.hr/assets/images/products/black500.webp',
  },
  {
    id: '8',
    title: '1X10.0mm2 H1Z2Z2-K BLACK DCA - 100MTR',
    category: 'cables_and_connectors',
    brand: 'Athilex',
    desc: '100m Cable, DCA',
    url: 'https://tesenergy.hr/assets/pdfs/TES_ENERGY_ATHILEX DCA.pdf',
    image: 'https://tesenergy.hr/assets/images/products/black100.webp',
  },
  {
    id: '9',
    title: '1X4.0mm2 H1Z2Z2-K BLACK DCA - 100MTR',
    category: 'cables_and_connectors',
    brand: 'Athilex',
    desc: '100m Cable, DCA',
    url: 'https://tesenergy.hr/assets/pdfs/TES_ENERGY_ATHILEX DCA.pdf',
    image: 'https://tesenergy.hr/assets/images/products/black100.webp',
  },
  {
    id: '10',
    title: '1X4.0mm2 H1Z2Z2-K BLACK DCA - 100MTR',
    category: 'cables_and_connectors',
    brand: 'Athilex',
    desc: '100m Cable, DCA',
    url: 'https://tesenergy.hr/assets/pdfs/TES_ENERGY_ATHILEX DCA.pdf',
    image: 'https://tesenergy.hr/assets/images/products/black100.webp',
  },
  {
    id: '11',
    title: 'Sungrow kit BATTERY SBRACC-V114',
    category: 'batteries',
    brand: 'Sungrow',
    desc: 'Sungrow SBR Battery Accessories',
    url: 'https://info-support.sungrowpower.com/application/pdf/2022/04/21/DS_20220421_SBR096_128_160_192_224_256_Datasheet_V13_EN.pdf',
    image: 'https://en.sungrowpower.com/upload/6375781627178274101490773.png',
  },
  {
    id: '12',
    title: 'Sungrow SBR battery module - 3.2kWh SMR032-V114',
    category: 'batteries',
    brand: 'Sungrow',
    desc: 'High Voltage LFP Battery, 3.2 kWh',
    url: 'https://info-support.sungrowpower.com/application/pdf/2022/04/21/DS_20220421_SBR096_128_160_192_224_256_Datasheet_V13_EN.pdf',
    image: 'https://en.sungrowpower.com/upload/6375781627178274101490773.png',
  },
  {
    id: '17',
    title: 'MIN 6000TL-XH',
    category: 'inverters',
    brand: 'Growatt',
    desc: ' Inverter 6kW, 1-phase, 2MPPTs, 13.5A, Battery ready ,Type II DC & Type III AC, W 10 years, IP65,compatible with  Wifi-X, Link-X, GPRS-X, LAN-X',
    url: 'https://tesenergy.hr/assets/pdfs/MIN2500~6000TL-XHDatasheet.pdf',
    image: 'https://tesenergy.hr/assets/images/products/17.jpg',
  },
  {
    id: '13',
    title: 'MAX 125KTL3-X LV',
    category: 'inverters',
    brand: 'Growatt',
    desc: ' String inverter 125kW,  3-Phase, 10MPPTs, 32A, Smart ,Transformerless, Type II DC & AC,  W 5 years, IP65',
    url: 'https://tesenergy.hr/assets/pdfs/datasheetmax100-125ktl3-x-lv.pdf',
    image: 'https://tesenergy.hr/assets/images/products/13.jpeg',
  },
  {
    id: '14',
    title: 'MAX 100KTL3-X LV',
    category: 'inverters',
    brand: 'Growatt',
    desc: ' String inverter 100 kW, 3-Phase, 10MPPTs, 32A, Hybrid,  Type II DC & AC, Transformerless, W 5 years, IP66',
    url: 'https://tesenergy.hr/assets/pdfs/datasheetmax100-125ktl3-x-lv.pdf',
    image: 'https://tesenergy.hr/assets/images/products/14.jpg',
  },
  {
    id: '15',
    title: 'SPH 10000 TL3 BH-UP',
    category: 'inverters',
    brand: 'Growatt',
    desc: ' Inverter 10kW, 3-Phase, 2MPPTs, 15.2A, Hybrid 15kw DC HV batteries, Parallel, Transformerless, Type II DC & AC, W 5 years, IP65',
    url: 'https://tesenergy.hr/assets/pdfs/SPH4000-10000TL3BHUPDatasheet.pdf',
    image: 'https://tesenergy.hr/assets/images/products/15.jpg',
  },
  {
    id: '16',
    title: 'SPF 5000ES',
    category: 'inverters',
    brand: 'Growatt',
    desc: 'Off-Grid Inverter 5kW 48V DC Bat 450V DC IN 18A, parallel 1MPPT',
    url: 'https://tesenergy.hr/assets/pdfs/Datasheet_Growatt-SPF5000-ES_2020_ENG.pdf',
    image: 'https://tesenergy.hr/assets/images/products/16.jpeg',
  },
  {
    id: '20',
    title: 'MAX 110KTL3-X LV',
    category: 'inverters',
    brand: 'Growatt',
    desc: 'Intelligent string monitoring, 110kW, 3Phase, 10 MPPTs, 32A, AC&DC type II SPD, W 5years, IP66',
    url: 'https://tesenergy.hr/assets/pdfs/datasheetmax100-125ktl3-x-lv.pdf',
    image: 'https://tesenergy.hr/assets/images/products/20.jpg',
  },
  {
    id: '21',
    title: 'MOD10KTL3-XH',
    category: 'inverters',
    brand: 'Growatt',
    desc: 'String Monitoring, 10kW, 3Phase, 2MPPTs, 13A, battery ready, Type II DC & AC, W 10 years, IP66, comp.with Wifi-X, Link-X, GPRS-X, LAN-X',
    url: 'https://tesenergy.hr/assets/pdfs/MOD-3-10KTL3-XH-Datasheet.pdf',
    image: 'https://tesenergy.hr/assets/images/products/21.jpg',
  },
  {
    id: '22',
    title: 'MID25KTL3-X',
    category: 'inverters',
    brand: 'Growatt',
    desc: 'Inverter, 25kW, 3Phase, 2/3MPPTs, 27A/40.5A, Type II DC & AC, W 5 years, IP65, Built-in arc flash protection (AFCI) , Touch key and OLED display, comp.with Master, Wifi-X, Link-X, GPRS-X, LAN-X',
    url: 'https://tesenergy.hr/assets/pdfs/MID-15K-25TL3-X-datasheet-.pdf',
    image: 'https://tesenergy.hr/assets/images/products/22.jpg',
  },
  {
    id: '23',
    title: 'MID40KTL3-X',
    category: 'inverters',
    brand: 'Growatt',
    desc: ' 40kW, 3Phase, 4MPPTs, 66.6A, Type II DC & AC, W 5 years, IP65, Built-in arc flash protection (AFCI) , Touch key and OLED display, comp.with Master, Wifi-X, Link-X, GPRS-X, LAN-X',
    url: 'https://tesenergy.hr/assets/pdfs/Datasheet-Growatt-MID-25-40KTL3-X-2.pdf',
    image: 'https://tesenergy.hr/assets/images/products/23.jpg',
  },
  {
    id: '24',
    title: 'TSM-405-DE09.08-TS4',
    category: 'solar_panels',
    brand: 'Trina',
    desc: 'Trina Vertex Mono 405 W - Half-Cut 1500V (Black Frame) (TS4) Vmpp 34.4V Impp 11.77A Voc 41.44V',
    url:'https://static.trinasolar.com/sites/default/files/EU_Datasheet_VertexS_DE09.08_2021_C.pdf',
    image: 'https://tesenergy.hr/assets/images/products/trina.webp'
  },
  {
    id: '25',
    title: 'N-Type Tiger Neo 415W Black Frame MC4',
    category: 'solar_panels',
    brand: 'Jinko Solar',
    desc: 'Jinko Solar N-Type Tiger Neo 415W Black Frame MC4 Imp 13.25A  Voc 37.92V',
    url:'https://jinkosolarcdn.shwebspace.com/uploads/JKM410-430N-54HL4-(V)-F2-EN.pdf',
    image: 'https://tesenergy.hr/assets/images/products/jinko.webp'
  },
];

export default Data;
