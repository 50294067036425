import create, { StateCreator } from 'zustand';
import { persist } from 'zustand/middleware';
import { Product } from '../components/productCard/data';
import { useEffect, useState } from 'react';

interface CartStore {
  cart: Product[];
  addToCart: (product: Product, quantity: number) => void;
  removeFromCart: (productId: string) => void;
  clearCart: () => void;
  updateQuantity: (productId: string, quantity: number) => void;
  toggleCart: boolean;
  setToogleCart: (setToogleCart: boolean) => void;
}

const useCartStore = create<CartStore>(
  persist(
    (set) => ({
      cart: [],
      addToCart: (product, quantity) => {
        set((state) => {
          const existingProduct = state.cart.find(p => p.id === product.id);
          if (existingProduct) {
            return {
              cart: state.cart.map(p =>
                p.id === product.id
                  ? { ...p, quantity: (p.quantity || 1) + quantity }
                  : p
              )
            };
          } else {
            return {
              cart: [...state.cart, { ...product, quantity }]
            };
          }
        });
      },
      removeFromCart: (productId) =>
        set((state) => ({
          cart: state.cart.filter((product) => product.id !== productId),
        })),
      clearCart: () => set({ cart: [] }),
      updateQuantity: (productId, quantity) => {
        set((state) => {
          if (quantity === 0) {
            return {
              cart: state.cart.filter((product) => product.id !== productId),
            };
          } else {
            return {
              cart: state.cart.map(p =>
                p.id === productId
                  ? { ...p, quantity }
                  : p
              )
            };
          }
        });
      },
      toggleCart: false,
      setToogleCart: (toggleCart: boolean) => set({ toggleCart: !toggleCart })
    }),
    {
      name: 'cart', // Name of the item in storage
      getStorage: () => localStorage, // Use localStorage for persistence
      partialize: (state) => ({ cart: state.cart }),
    }
  ) as StateCreator<CartStore>
  
);

function getProductsFromStorage(): any[] {
  let cartContents: any[] = JSON.parse(localStorage.getItem('cart') || '[]')
  // setProducts(cartContents)
  return cartContents
}



export default useCartStore;