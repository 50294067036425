import React, { useEffect, useState } from 'react'
import ReactModal from 'react-modal';
import { useForm } from "react-hook-form";
import { ParagraphComponent, SubtitleComponent, ButtonComponent } from '../';
import { useTranslation } from 'react-i18next';
import { Product } from '../productCard/data';
import { useLocation, useNavigate } from "react-router-dom";


import './productModal.style.scss'

//@ts-ignore
import closeIcon from '../../assets/icons/closeIcon.png';
//@ts-ignore
import downloadIcon from '../../assets/icons/download.svg';
//@ts-ignore
import cartIcon from '../../assets/icons/cart.svg';
//@ts-ignore
import imagePlaceholder from '../../assets/images/imagePlaceholder.svg';

import { CategoryEquipment } from '../../pages/equipment/categories';
import { CategoryChargers } from '../../pages/chargers/categories';
import useCartStore from '../../store/cartStore';
import { Helmet } from 'react-helmet';


interface Props {
    openModal: boolean;
    closeModal: any;
    selectedProduct?: Product;
    singleProductDisplay?: boolean;
}

function ProductModalComponent(props: Props) {
    const { t, i18n } = useTranslation();
    const { openModal, closeModal, selectedProduct, singleProductDisplay } = props;
    const [showModal, setShowModal] = useState(false);
    const [productQuantity, setProductQuantity] = useState(1)
    const { addToCart } = useCartStore();
    const navigate = useNavigate();
    const location = useLocation();

    const removeLastSegment = (url: string): string => {
        const segments = url.split('/');
        if (segments.length > 1) {
            segments.pop();
        }
        return segments.join('/');
    };


    const {
        register,
        handleSubmit,
        reset,
        formState,
        formState: { errors },
        formState: { isSubmitSuccessful },
        setValue
    } = useForm<FormData>();

    let categoriesEquipment: CategoryEquipment = t('solarEquipment.categories')
    let categoriesChargers: CategoryChargers = t('chargersEquipment.categories')

    function renderCategory(currentCategory: string) {
        // if (chargers) {
        //     if (currentCategory as keyof CategoryChargers) {
        //         return (categoriesChargers[currentCategory as keyof CategoryChargers]);
        //     }
        // }
        if (currentCategory as keyof CategoryEquipment) {
            return (categoriesEquipment[currentCategory as keyof CategoryEquipment]);
        }
        else return ('');
    }

    function addProductToCart(quantity: number) {

        let cartContents: any[] = JSON.parse(localStorage.getItem('cart') || '[]')

        if (selectedProduct) { addToCart(selectedProduct, quantity) }

        // Check if the item already exists in the cart
        // const existingItemIndex = cartContents.findIndex(item => item.id === selectedProduct?.id);

        // let updatedItems;
        // if (existingItemIndex !== -1) {
        //     // Update quantity if item already exists
        //     updatedItems = cartContents.map(item =>
        //         item.id === selectedProduct?.id ? { ...item, quantity: item.quantity + productQuantity } : item
        //     );
        // } else {
        //     // Add new item if it doesn't exist
        //     updatedItems = [...cartContents, selectedProduct];
        // }

        // selectedProduct!.quantity = productQuantity;
        // cartContents.push(selectedProduct)
        // localStorage.setItem('cart', JSON.stringify(updatedItems))

    }

    useEffect(() => {
        setProductQuantity(1)
    }, [closeModal])



    return (
        <>
            {/* <Helmet>
                <title>{selectedProduct?.title}</title>
                <meta name="description" content={selectedProduct?.desc} />
            </Helmet> */}
            <ReactModal
                isOpen={openModal}
                className="modal"
                overlayClassName="overlay"
                ariaHideApp={false}
                closeTimeoutMS={200}
                contentLabel="modal"
                onRequestClose={singleProductDisplay ? () => navigate(removeLastSegment(location.pathname)) : closeModal}
            >
                <div className="closeIconContainer">
                    <img className='closeIcon' onClick={singleProductDisplay ? () => navigate(-1) : () => setShowModal(closeModal
                    )} src={closeIcon} alt="Close Icon" />
                </div>
                <div className="productModalContainer">
                    <div className="imageDiv">
                        <img src={selectedProduct?.image === undefined ? imagePlaceholder : selectedProduct?.image} alt={`Product image of ${selectedProduct?.title}`} />
                    </div>

                    <div className="contentDiv">
                        <h2><SubtitleComponent className='productTitle' white>{selectedProduct?.title}</SubtitleComponent></h2>
                        <ParagraphComponent className='productBrand'>{renderCategory(selectedProduct?.category || '')} {selectedProduct?.brand ? '-' : null} {selectedProduct?.brand}</ParagraphComponent>
                        <h3><ParagraphComponent className='productDescription' dark>{selectedProduct?.desc}</ParagraphComponent></h3>
                        {selectedProduct?.url && <h3><ParagraphComponent className='productSpecifications' dark><a href={selectedProduct?.url!}>{t('productsCard.specifications')}<img className='downloadIcon' src={downloadIcon} /></a></ParagraphComponent></h3>}
                        <h3><SubtitleComponent className={`productPrice ${selectedProduct?.stock === 0 && 'outOfStockPrice'}`}> {selectedProduct?.price?.toFixed(2).toString().replace(".", ",")} &euro;</SubtitleComponent></h3>
                        <h4><ParagraphComponent className='productStock'>{selectedProduct?.stock ? t('productsCard.inStock') + ' ' + selectedProduct?.stock?.toString() : t('productsCard.outOfStock')}</ParagraphComponent></h4>
                        {selectedProduct?.stock ?
                            <div className="buttonContainer"><ButtonComponent categoryId='addToCart' onClick={() => { addProductToCart(productQuantity); closeModal() }}>{t('productsCard.addToCart')}<img className='cartIcon' src={cartIcon} /></ButtonComponent>
                                <input type='number' max={selectedProduct?.stock} value={productQuantity} onChange={(event) => { setProductQuantity(Number(event.target.value)) }} min={1} /></div> : <ButtonComponent ghost style={{ marginTop: '1rem' }} onClick={() => { }}>{t('productsCard.outOfStock')}</ButtonComponent>}

                    </div>
                </div>

            </ReactModal >
        </>
    )
}

export default ProductModalComponent;