import i18next from 'i18next';
import React, { useEffect, useState } from 'react'
import { useTranslation, withTranslation } from 'react-i18next';
import { NavLink, useNavigate } from 'react-router-dom';
import { changeLanguage } from 'i18next';


//@ts-ignore;
import arrowDown from '../../assets/icons/arrowDown.png';
//@ts-ignore;
import closeMenu from '../../assets/icons/closeMenu.png';
//@ts-ignore;
import closeIcon from '../../assets/icons/closeIcon.png';
import { ParagraphComponent } from '../typography';
import Flag from 'react-flagpack';
import { getCookieConsentValue } from 'react-cookie-consent';

interface Dropdown {
    services?: boolean,
    products?: boolean,
    lang?: boolean,
    solarProducts?: boolean,
    chargers?: boolean,
    closeMobile?: boolean,
    setCloseMobile?: (closeMobile: boolean) => void
}

function DropdownComponent(props: Dropdown) {
    const { t, i18n } = useTranslation();
    const { services, products, lang, solarProducts, chargers, closeMobile, setCloseMobile } = props;
    const [click, setClick] = useState(false)
    const navigate = useNavigate();

    // function changePageLanguage() {
    //     localStorage.setItem("lng", i18n.resolvedLanguage)
    // }

    function changePageLanguage() {
        if (getCookieConsentValue()?.toString() === "true") { localStorage.setItem("lng", i18n.resolvedLanguage) }
        navigate("/" + i18n.resolvedLanguage + t('navbar.routes.home'))
    }
    const langSubmenus = [
        { title: t('navbar.dropdownLinks.lang.hr'), path: 'hr' },
        { title: t('navbar.dropdownLinks.lang.en'), path: 'en' },
    ];
    const productsSubmenus = [
        { title: t('navbar.dropdownLinks.products.equipment'), path: i18next.t('navbar.routes.equipment') },
        // { title: t('navbar.dropdownLinks.products.electro'), path: '/electro' },
        { title: t('navbar.dropdownLinks.products.chargers'), path: i18next.t('navbar.routes.chargers') },
        // { title: t('navbar.dropdownLinks.products.automatization'), path: '/automatization' },
        { title: t('navbar.dropdownLinks.services.mountingSystems'), path: i18next.t('navbar.routes.mountingSystems') },
        { title: t('navbar.dropdownLinks.services.heatPumps'), path: i18next.t('navbar.routes.heatPumps') },
    ];
    const solarProductsSubmenus = [
        { title: t('solarEquipment.categories.solar_panels'), path: i18next.t('navbar.routes.solarPanels') },
        { title: t('solarEquipment.categories.inverters'), path: i18next.t('navbar.routes.inverters') },
        { title: t('solarEquipment.categories.batteries'), path: i18next.t('navbar.routes.batteries') },
        { title: t('solarEquipment.categories.cables_and_connectors'), path: i18next.t('navbar.routes.cables') },
    ];
    const EVSubmenus = [
        { title: t('chargersEquipment.categoriesDropdown.chargers'), path: i18next.t('navbar.routes.evChargers') },
        { title: t('chargersEquipment.categoriesDropdown.chargerAccessories'), path: i18next.t('navbar.routes.chargerAccessories') },
    ];
    const servicesSubmenus = [
        { title: t('navbar.dropdownLinks.services.solar'), path: i18next.t('navbar.routes.solar') },
        { title: t('navbar.dropdownLinks.services.software'), path: i18next.t('navbar.routes.software') },
        { title: t('navbar.dropdownLinks.services.diagnostics'), path: i18next.t('navbar.routes.diagnostics') },
    ];

    useEffect(() => {
        setClick(false)

    }, [click])


    return (
        services ?
            <ul className="dropdown services">{
                servicesSubmenus.map((submenu, index) => (
                    <li key={index} className="menu-items">
                        <NavLink onClick={() => {
                            setClick(true)
                            if (setCloseMobile) setCloseMobile(!closeMobile)
                        }} to={i18n.resolvedLanguage + submenu.path}>{submenu.title}</NavLink>
                    </li>
                ))}
            </ul>
            : products ?
                <ul className={`dropdown ${click ? 'closeDropdown' : ''} `}>{
                    productsSubmenus.map((submenu, index) => (
                        index === 0 ?
                            <div key={index} className='dropdownLink productsNested'>
                                <li className="menu-items">
                                    <div className="productsLink" >
                                        <NavLink onClick={() => {
                                            setClick(true)
                                            if (setCloseMobile) setCloseMobile(!closeMobile)
                                        }} to={i18n.resolvedLanguage + submenu.path}>{submenu.title}</NavLink>
                                    </div>
                                </li>
                                <DropdownComponent solarProducts closeMobile={closeMobile} setCloseMobile={setCloseMobile} />
                                <img className='expandRightIcon' src={arrowDown} alt="arrowDown Icon" />
                            </div>
                            : index === 1 ?
                                <div key={index} className='dropdownLink productsNested' style={{ zIndex: 99 }}>
                                    <li className="menu-items">
                                        <div className="productsLink" style={{ width: 'max-content' }} ><NavLink onClick={() => {
                                            setClick(true)
                                            if (setCloseMobile) setCloseMobile(!closeMobile)

                                        }} to={i18n.resolvedLanguage + submenu.path}>{submenu.title}</NavLink>
                                        </div>
                                    </li>
                                    <DropdownComponent chargers closeMobile={closeMobile} setCloseMobile={setCloseMobile} />
                                    <img className='expandRightIcon' src={arrowDown} alt="arrowDown Icon" />
                                </div>
                                :
                                <li key={index} className="menu-items">
                                    <NavLink onClick={() => {
                                        click && setClick(!click)
                                        if (setCloseMobile) setCloseMobile(!closeMobile)

                                    }} to={i18n.resolvedLanguage + submenu.path}>{submenu.title}</NavLink>
                                </li>

                    ))}
                </ul>
                : solarProducts ?
                    <ul className={`dropdown nested ${click ? 'closeDropdown' : ''}`}>{
                        solarProductsSubmenus.map((submenu, index) => (
                            <li key={index} className="menu-items">
                                <NavLink onClick={() => {
                                    setClick(true)
                                    if (setCloseMobile) setCloseMobile(!closeMobile)
                                }} to={i18n.resolvedLanguage + t('navbar.routes.equipment') + submenu.path}>{submenu.title}</NavLink>
                            </li>

                        ))}
                        <li className="menu-items">
                            <NavLink onClick={() => {
                                setClick(true)
                                if (setCloseMobile) setCloseMobile(!closeMobile)
                            }} to={i18n.resolvedLanguage + t('navbar.routes.equipment')}>{t('solarEquipment.categories.all')}</NavLink>
                        </li>
                    </ul>
                    : chargers ?
                        <ul className={`dropdown nested ${click ? 'closeDropdown' : ''}`}>{
                            EVSubmenus.map((submenu, index) => (
                                <li key={index} className="menu-items">
                                    <NavLink onClick={() => {
                                        setClick(true)
                                        if (setCloseMobile) setCloseMobile(!closeMobile)
                                    }} to={i18n.resolvedLanguage + t('navbar.routes.chargers') + submenu.path}>{submenu.title}</NavLink>
                                </li>

                            ))}
                            <li className="menu-items">
                                <NavLink onClick={() => {
                                    setClick(true)
                                    if (setCloseMobile) setCloseMobile(!closeMobile)
                                }} to={i18n.resolvedLanguage + t('navbar.routes.chargers')}>{t('solarEquipment.categories.all')}</NavLink>
                            </li>
                        </ul>
                        : lang ?
                            // <ul className="dropdown">{
                            //     langSubmenus.map((submenu, index) => (
                            //         <li key={index} className="menu-items">
                            //             <ParagraphComponent onClick={() => { changeLanguage(submenu.path); changePageLanguage() }}>{submenu.title}</ParagraphComponent>
                            //         </li>
                            //     ))}
                            // </ul>

                            <ul className="dropdown services langChange">
                                <li className="menu-items">
                                    <ParagraphComponent onClick={() => { changeLanguage('en'); changePageLanguage() }}><Flag size='m' code={'GB-UKM'} />ENG</ParagraphComponent>
                                </li>
                                <li className="menu-items">
                                    <ParagraphComponent onClick={() => { changeLanguage('hr'); changePageLanguage(); }}><Flag size='m' code={'HR'} />HRV</ParagraphComponent>
                                </li>
                            </ul>
                            : <ul className="dropdown"><li className="menu-items"></li></ul>

    )
}

export default (DropdownComponent);