import { Helmet } from 'react-helmet';
import { ParagraphComponent, SubtitleComponent, TitleComponent } from '../../components';
import './notFound.style.scss';
import { Link } from 'react-router-dom';
import i18n from '../../i18n';
import { withTranslation } from 'react-i18next';

function NotFound({ t }: { t: any }) {
  return (
    <div className='notFound'>
      <Helmet>
        <title>{t('notFound.title')}</title>
      </Helmet>
      <TitleComponent medium>{t('notFound.t1')}</TitleComponent>
      <TitleComponent moreSmaller medium >{t('notFound.t2')}</TitleComponent>
      <ParagraphComponent margins>{t('notFound.t3')}</ParagraphComponent>
      <div className="linksDiv">
        <div className="linksDivTitle"><SubtitleComponent>{t('notFound.links')}</SubtitleComponent>
        </div>

        <div className="homeLinks">
          <ParagraphComponent margins link> <Link to={"/" + i18n.resolvedLanguage + t('navbar.routes.home')}>{t('navbar.mainLinks.home')}</Link> </ParagraphComponent>
          <ParagraphComponent margins link> <Link to={"/" + i18n.resolvedLanguage + t('navbar.routes.about')}>{t('navbar.mainLinks.about')}</Link> </ParagraphComponent>
        </div>
        <div className="productsLinks">
          <ParagraphComponent margins link> <Link to={"/" + i18n.resolvedLanguage + t('navbar.routes.equipment')}>{t('navbar.dropdownLinks.products.equipment')}</Link> </ParagraphComponent>
          <ParagraphComponent margins link> <Link to={"/" + i18n.resolvedLanguage + t('navbar.routes.chargers')}>{t('navbar.dropdownLinks.products.chargers')}</Link> </ParagraphComponent>
          <ParagraphComponent margins white link> <Link to={"/" + i18n.resolvedLanguage + t('navbar.routes.heatPumps')}>{t('navbar.dropdownLinks.services.heatPumps')}</Link> </ParagraphComponent>
          <ParagraphComponent margins white link> <Link to={"/" + i18n.resolvedLanguage + t('navbar.routes.mountingSystems')}>{t('navbar.dropdownLinks.services.mountingSystems')}</Link> </ParagraphComponent>
        </div>
        <div className="servicesLinks">
          <ParagraphComponent margins white link> <Link to={"/" + i18n.resolvedLanguage + t('navbar.routes.solar')}>{t('navbar.dropdownLinks.services.solar')}</Link> </ParagraphComponent>
          <ParagraphComponent margins white link> <Link to={"/" + i18n.resolvedLanguage + t('navbar.routes.software')}>{t('navbar.dropdownLinks.services.software')}</Link> </ParagraphComponent>
          <ParagraphComponent margins white link> <Link to={"/" + i18n.resolvedLanguage + t('navbar.routes.references')}>{t('navbar.dropdownLinks.services.references')}</Link> </ParagraphComponent>
        </div>
      </div>
    </div>
  )
}
export default withTranslation()(NotFound);


