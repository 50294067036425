import create from 'zustand';
import { Pagination, Product } from '../components/productCard/data';
import { decode } from 'html-entities';

interface Products {
    products: Product[],
    pagination: Pagination
}


interface ProductStore {
    solarPanels: Products;
    inverters: Products;
    batteries: Products;
    cables: Products;
    chargers: Products;
    charger_accessories: Products;
    product: Product | null;
    resetProduct: () => void;
    loading: boolean;
    error: string | null;
    fetchSolarPanels: (page: number) => Promise<void>;
    fetchInverters: (page: number) => Promise<void>;
    fetchBatteries: (page: number) => Promise<void>;
    fetchCables: (page: number) => Promise<void>;
    fetchChargers: (page: number) => Promise<void>;
    fetchChargerAccessories: (page: number) => Promise<void>;
    fetchProduct: (id: number) => Promise<void>;
    setLoading: (loading: boolean) => void;
}

const backendUrl = 'https://api.tesenergy.hr'

const useProductStore = create<ProductStore>((set) => ({
    solarPanels: { products: [], pagination: {} },
    batteries: { products: [], pagination: {} },
    inverters: { products: [], pagination: {} },
    cables: { products: [], pagination: {} },
    chargers: { products: [], pagination: {} },
    charger_accessories: { products: [], pagination: {} },
    product: null,
    resetProduct: () => set({ product: null }),
    loading: true,
    error: null,
    fetchSolarPanels: async (page: number) => {
        if (page === 0) {
            set({ loading: true, error: null });
        } fetch(`${backendUrl}/products/solar-products/page=${page}`, {
            method: 'GET',
            headers: { 'Accept': 'application/json' },
        })
            .then((response) => response.json())
            .then((result) => {
                return Promise.all(result['data'].map(async (value: any) => {
                    let sumOfStock = 0
                    if (value.multiprices[1] > 0) {
                        Promise.all([fetchProductStock(value.id) || [], fetchProductImage(value.id)]).then(([stock, image]) => {
                            if (image) {
                                if (!stock.error) {
                                    stock = stock['stock_warehouses']
                                    Object.keys(stock).map((stockValue: any, index) => {
                                        stockAvailability(stockValue).then(stockRresult => {
                                            if (stockRresult) {
                                                sumOfStock = Number(stock[stockValue].real)
                                            }
                                            if (index == 0) {
                                                set((state) => ({
                                                    solarPanels: {
                                                        products: [...state.solarPanels.products, { id: value.id, brand: value.array_options['options_brand'], title: value.array_options['options_title'], desc: decode(value.description), url: value.url, price: Number(value.multiprices[1]), image: image, category: 'solar_panels', stock: sumOfStock }],
                                                        pagination: result.pagination
                                                    }
                                                }))
                                            }
                                        })

                                    })
                                }
                                else if (stock.error) {
                                    set((state) => ({
                                        solarPanels: {
                                            products: [...state.solarPanels.products, { id: value.id, brand: value.array_options['options_brand'], title: value.array_options['options_title'], desc: decode(value.description), url: value.url, price: Number(value.multiprices[1]), image: image, category: 'solar_panels', stock: 0 }],
                                            pagination: result.pagination
                                        }
                                    }))
                                }
                            }

                        })
                    } else {
                        set({
                            loading: false, error: null
                        })
                    }

                })).then(() => {
                    set({
                        loading: false, error: null
                    })
                })
            })
            .catch((error) => {
                console.error('There was a problem with the fetch operation:', error);
                set({
                    loading: true, error: error
                })
            })
    },
    fetchInverters: async (page?: number) => {
        if (page === 0) {
            set({ loading: true, error: null });
        }
        fetch(`${backendUrl}/products/inverters/page=${page}`, {
            method: 'GET',
            headers: { 'Accept': 'application/json' },
        })
            .then((response) => response.json())
            .then((result) => {
                return Promise.all(result['data'].map(async (value: any) => {
                    let sumOfStock = 0
                    if (value.multiprices[1] > 0) {
                        Promise.all([fetchProductStock(value.id) || [], fetchProductImage(value.id)]).then(([stock, image]) => {
                            if (image) {
                                if (!stock.error) {
                                    stock = stock['stock_warehouses']
                                    Object.keys(stock).map((stockValue: any, index) => {
                                        stockAvailability(stockValue).then(stockResult => {
                                            if (stockResult) {
                                                sumOfStock = Number(stock[stockValue].real)
                                            }
                                            if (index == 0) {
                                                set((state) => ({
                                                    inverters: {
                                                        products: [...state.inverters.products, { id: value.id, brand: value.array_options['options_brand'], title: value.array_options['options_title'], desc: decode(value.description), url: value.url, price: Number(value.multiprices[1]), image: image, category: 'inverters', stock: sumOfStock }],
                                                        pagination: result.pagination
                                                    }
                                                }))
                                            }
                                        })

                                    })
                                }
                                else if (stock.error) {
                                    set((state) => ({
                                        inverters: {
                                            products: [...state.inverters.products, { id: value.id, brand: value.array_options['options_brand'], title: value.array_options['options_title'], desc: decode(value.description), url: value.url, price: Number(value.multiprices[1]), image: image, category: 'inverters', stock: 0 }],
                                            pagination: result.pagination
                                        }
                                    }))
                                }
                            }

                        }

                        )
                    } else {
                        set({
                            loading: false, error: null
                        })
                    }
                })).then(() => {
                    set({
                        loading: false, error: null
                    })
                })
            })
            .catch((error) => {
                console.error('There was a problem with the fetch operation:', error);
                set({
                    loading: true, error: error
                })
            })
    },
    fetchBatteries: async (page: number) => {
        if (page === 0) {
            set({ loading: true, error: null });
        }
        fetch(`${backendUrl}/products/batteries/page=${page}`, {
            method: 'GET',
            headers: { 'Accept': 'application/json' },
        })
            .then((response) => response.json())
            .then((result) => {
                return Promise.all(result['data'].map(async (value: any) => {
                    let sumOfStock = 0
                    if (value.multiprices[1] > 0) {
                        // fetchProductStock(value.id)
                        Promise.all([fetchProductStock(value.id) || [], fetchProductImage(value.id)]).then(([stock, image]) => {
                            if (image) {
                                if (!stock.error) {
                                    stock = stock['stock_warehouses']
                                    Object.keys(stock).map((stockValue: any, index) => {
                                        stockAvailability(stockValue).then(stockResult => {
                                            if (stockResult) {
                                                sumOfStock = Number(stock[stockValue].real)
                                            }
                                            if (index == 0) {
                                                set((state) => ({
                                                    batteries: {
                                                        products: [...state.batteries.products, { id: value.id, brand: value.array_options['options_brand'], title: value.array_options['options_title'], desc: decode(value.description), url: value.url, price: Number(value.multiprices[1]), image: image, category: 'batteries', stock: sumOfStock }],
                                                        pagination: result.pagination
                                                    }
                                                }))
                                            }
                                        })

                                    })
                                }
                                else if (stock.error) {
                                    set((state) => ({
                                        batteries: {
                                            products: [...state.batteries.products, { id: value.id, brand: value.array_options['options_brand'], title: value.array_options['options_title'], desc: decode(value.description), url: value.url, price: Number(value.multiprices[1]), image: image, category: 'batteries', stock: 0 }],
                                            pagination: result.pagination
                                        }
                                    }))
                                }
                            }
                        })
                    } else {
                        set({
                            loading: false, error: null
                        })
                    }

                })).then(() => {
                    set({
                        loading: false, error: null
                    })
                })

            })
            .catch((error) => {
                console.error('There was a problem with the fetch operation:', error);
                set({
                    loading: true, error: error
                })
            })
    },
    fetchCables: async (page: number) => {
        if (page === 0) {
            set({ loading: true, error: null });
        }
        fetch(`${backendUrl}/products/cables/page=${page}`, {
            method: 'GET',
            headers: { 'Accept': 'application/json' },
        })
            .then((response) => response.json())
            .then((result) => {
                return Promise.all(result['data'].map(async (value: any) => {
                    let sumOfStock = 0
                    if (value.multiprices[1] > 0) {
                        // fetchProductStock(value.id)
                        Promise.all([fetchProductStock(value.id) || [], fetchProductImage(value.id)]).then(([stock, image]) => {
                            if (image) {
                                if (!stock.error) {
                                    stock = stock['stock_warehouses']
                                    Object.keys(stock).map((stockValue: any, index) => {
                                        stockAvailability(stockValue).then(stockResult => {
                                            if (stockResult) {
                                                sumOfStock = Number(stock[stockValue].real)
                                            }
                                            if (index == 0) {
                                                set((state) => ({
                                                    cables: {
                                                        products: [...state.cables.products, { id: value.id, brand: value.array_options['options_brand'], title: value.array_options['options_title'], desc: decode(value.description), url: value.url, price: Number(value.multiprices[1]), image: image, category: 'cables_and_connectors', stock: sumOfStock }],
                                                        pagination: result.pagination
                                                    }
                                                }))
                                            }
                                        })

                                    })
                                }
                                else if (stock.error) {
                                    set((state) => ({
                                        cables: {
                                            products: [...state.cables.products, { id: value.id, brand: value.array_options['options_brand'], title: value.array_options['options_title'], desc: decode(value.description), url: value.url, price: Number(value.multiprices[1]), image: image, category: 'cables_and_connectors', stock: 0 }],
                                            pagination: result.pagination
                                        }
                                    }))
                                }
                            }


                        }

                        )
                    } else {
                        set({
                            loading: false, error: null
                        })
                    }

                })).then(() => {
                    set({
                        loading: false, error: null
                    })
                })

            })
            .catch((error) => {
                console.error('There was a problem with the fetch operation:', error);
                set({
                    loading: true, error: error
                })
            })
    },
    fetchChargers: async (page: number) => {
        if (page === 0) {
            set({ loading: true, error: null });
        }
        fetch(`${backendUrl}/products/chargers/page=${page}`, {
            method: 'GET',
            headers: { 'Accept': 'application/json' },
        })
            .then((response) => response.json())
            .then((result) => {
                return Promise.all(result['data'].map(async (value: any) => {
                    let sumOfStock = 0
                    if (value.multiprices[1] > 0) {
                        // fetchProductStock(value.id)
                        Promise.all([fetchProductStock(value.id) || [], fetchProductImage(value.id)]).then(([stock, image]) => {
                            if (image) {
                                if (!stock.error) {
                                    stock = stock['stock_warehouses']
                                    Object.keys(stock).map((stockValue: any, index) => {
                                        stockAvailability(stockValue).then(stockResult => {
                                            if (stockResult) {
                                                sumOfStock = Number(stock[stockValue].real)
                                            }
                                            if (index == 0) {
                                                set((state) => ({
                                                    chargers: {
                                                        products: [...state.chargers.products, { id: value.id, brand: value.array_options['options_brand'], title: value.array_options['options_title'], desc: decode(value.description), url: value.url, price: Number(value.multiprices[1]), image: image, category: 'chargers', stock: sumOfStock }],
                                                        pagination: result.pagination
                                                    }
                                                }))
                                            }
                                        })

                                    })
                                }
                                else if (stock.error) {
                                    set((state) => ({
                                        chargers: {
                                            products: [...state.chargers.products, { id: value.id, brand: value.array_options['options_brand'], title: value.array_options['options_title'], desc: decode(value.description), url: value.url, price: Number(value.multiprices[1]), image: image, category: 'chargers', stock: 0 }],
                                            pagination: result.pagination
                                        }
                                    }))
                                }
                            }
                        }

                        )
                    } else {
                        set({
                            loading: false, error: null
                        })
                    }

                })).then(() => {
                    set({
                        loading: false, error: null
                    })
                })

            })
            .catch((error) => {
                console.error('There was a problem with the fetch operation:', error);
                set({
                    loading: true, error: error
                })
            })
    },
    fetchChargerAccessories: async (page: number) => {
        if (page === 0) {
            set({ loading: true, error: null });
        }
        fetch(`${backendUrl}/products/charger-accessories/page=${page}`, {
            method: 'GET',
            headers: { 'Accept': 'application/json' },
        })
            .then((response) => response.json())
            .then((result) => {
                return Promise.all(result['data'].map(async (value: any) => {
                    let sumOfStock = 0
                    if (value.multiprices[1] > 0) {
                        // fetchProductStock(value.id)
                        Promise.all([fetchProductStock(value.id) || [], fetchProductImage(value.id)]).then(([stock, image]) => {
                            if (image) {
                                if (!stock.error) {
                                    stock = stock['stock_warehouses']
                                    Object.keys(stock).map((stockValue: any, index) => {
                                        stockAvailability(stockValue).then(stockResult => {
                                            if (stockResult) {
                                                sumOfStock = Number(stock[stockValue].real)
                                            }
                                            if (index == 0) {
                                                set((state) => ({
                                                    charger_accessories: {
                                                        products: [...state.charger_accessories.products, { id: value.id, brand: value.array_options['options_brand'], title: value.array_options['options_title'], desc: decode(value.description), url: value.url, price: Number(value.multiprices[1]), image: image, category: 'charger_accessories', stock: sumOfStock }],
                                                        pagination: result.pagination
                                                    }
                                                }))
                                            }
                                        })

                                    })
                                }
                                else if (stock.error) {
                                    set((state) => ({
                                        charger_accessories: {
                                            products: [...state.charger_accessories.products, { id: value.id, brand: value.array_options['options_brand'], title: value.array_options['options_title'], desc: decode(value.description), url: value.url, price: Number(value.multiprices[1]), image: image, category: 'charger_accessories', stock: 0 }],
                                            pagination: result.pagination
                                        }
                                    }))
                                }
                            }
                        }
                        )
                    } else {
                        set({
                            loading: false, error: null
                        })
                    }
                })).then(() => {
                    set({
                        loading: false, error: null
                    })
                });

            })
            .catch((error) => {
                console.error('There was a problem with the fetch operation:', error);
                set({
                    loading: true, error: error
                })
            })
    },
    fetchProduct: async (id: number) => {
        set({ loading: true, error: null });
        fetch(`${backendUrl}/products/${id}`, {
            method: 'GET',
            headers: { 'Accept': 'application/json' },
        })
            .then((response) => response.json())
            .then((result) => {
                let sumOfStock = 0
                if (result.multiprices[1] > 0) {
                    Promise.all([fetchProductStock(result.id) || [], fetchProductImage(result.id), fetchCategory(result.id)]).then(([stock, image, category]) => {
                        if (image) {
                            if (!stock.error) {
                                stock = stock['stock_warehouses']
                                Object.keys(stock).map((stockValue: any, index) => {
                                    stockAvailability(stockValue).then(stockRresult => {
                                        if (stockRresult) {
                                            sumOfStock = Number(stock[stockValue].real)
                                        }
                                        if (index == 0) {
                                            set({
                                                product: {
                                                    id: result.id, brand: result.array_options['options_brand'], title: result.array_options['options_title'], desc: decode(result.description), url: result.url, price: Number(result.multiprices[1]), image: image, category: category, stock: sumOfStock
                                                }
                                            })
                                        }
                                    })

                                })
                            }
                            else if (stock.error) {
                                set({
                                    product: {
                                        id: result.id, brand: result.array_options['options_brand'], title: result.array_options['options_title'], desc: decode(result.description), url: result.url, price: Number(result.multiprices[1]), image: image, category: category, stock: 0
                                    }
                                })
                            }
                        }

                    }).then(() => {
                        set({
                            loading: false, error: null
                        })
                    })
                }
                else {
                    set({
                        loading: false, error: null
                    })
                }


            })
            .catch((error) => {
                console.error('There was a problem with the fetch operation:', error);
                set({
                    loading: false, error: error
                })
            })
    },
    setLoading: (loading) => set({ loading }),

}));

export function isImageFile(filename: string) {
    const imageExtensions = /\.(jpg|jpeg|png|webp)$/i;
    return imageExtensions.test(filename);
}

export const sortProductsByStock = (products: Product[]): Product[] => {
    return products.sort((a, b) => {
        if (a.stock === 0 && b.stock !== 0) {
            return 1;
        } else if (a.stock !== 0 && b.stock === 0) {
            return -1;
        } else {
            return 0;
        }
    });
};

export const fetchProductStock = async function (productId: Number) {
    let stock: any

    await fetch(`${backendUrl}/products/stock/${productId}`, {
        method: 'GET',
        headers: { 'Accept': 'application/json' },
    })
        .then((response) => {
            return response.json()
        })
        .then((result) => {
            stock = result
        }).catch((error) => {
            console.error('There was a problem with the fetch operation:', error);
        })

    return stock
};

export const stockAvailability = async function (warehouseId: Number) {
    let available = false;

    await fetch(`${backendUrl}/products/warehouses/${warehouseId}`, {
        method: 'GET',
        headers: { 'Accept': 'application/json' },
    })
        .then((response) => response.json())
        .then((result) => {
            if (result['ref'].endsWith("_out")) {
                available = false
            }
            else available = true

        })
        .catch((error) => {
            console.error('There was a problem with the fetch operation:', error);
        })
    return available
};

export const fetchCategory = async function (productId: Number) {
    let category = ''

    await fetch(`${backendUrl}/products/category/${productId}`, {
        method: 'GET',
        headers: { 'Accept': 'application/json' },
    })
        .then((response) => response.json())
        .then((result) => {
            if (result[1]) {
                category = result[1].label
            }

        })
        .catch((error) => {
            console.error('There was a problem with the fetch operation:', error);
        })

    return category
};

export const fetchProductImage = async function (productId: Number) {
    let image: string = ''
    let imagePath: string = ''
    let images: string[] = []

    await fetch(`${backendUrl}/products/product-image/id=${productId}`, {
        method: 'GET',
        headers: { 'Accept': 'application/json' },
    })
        .then((response) => response.json())
        .then((result) => {
            Promise.all(Object.keys(result).map((value: any) => {
                if (isImageFile(result[value].relativename)) {
                    image = result[value].relativename
                    imagePath = result[value].path
                    imagePath = imagePath.slice(imagePath.indexOf("/html") + "/html".length)
                    imagePath = 'https://pv.hr' + imagePath + '/' + image
                    images.push(imagePath)
                }
            }))
        })
        .then(() => {
            return images[0]
        })
        .catch((error) => {
            console.error('There was a problem with the fetch operation:', error);
        })
    return images[0]
};
export const findProductById = (products: Product[], id: string): Product | undefined => {
    return products.find(product => product.id === id);
};
export default useProductStore;