import React, { useEffect, useState } from 'react'
import axios from 'axios';
import ReactModal from 'react-modal';
import { useForm } from "react-hook-form";
import { ParagraphComponent, SubtitleComponent, TitleComponent, ButtonComponent } from '../';
import { useTranslation } from 'react-i18next';
import { data } from '../productCard/data';

import './modal.style.scss'

//@ts-ignore
import closeIcon from '../../assets/icons/closeIcon.png';
//@ts-ignore
import checkIcon from '../../assets/icons/checkIcon.svg';
//@ts-ignore
import errorIcon from '../../assets/icons/errorIcon.svg';


interface Props {
    openModal: boolean;
    closeModal: any;
    selectedProduct?: data;
}

type FormData = {
    name: string;
    email: string;
    selectedProduct: string;
    messageContent: string;
    dataProtection: boolean;
    terms: boolean;
};

const API_PATH = 'https://tesenergy.hr/api/contact/action_page.php';

function ModalComponent(props: Props) {
    const { t, i18n } = useTranslation();
    const { openModal, closeModal } = props;
    const [showModal, setShowModal] = useState(false);
    const { selectedProduct } = props;
    // const [productTitle, setProductTitle] = useState(selectedProduct?.title);

    const {
        register,
        handleSubmit,
        reset,
        formState,
        formState: { errors },
        formState: { isSubmitSuccessful },
        setValue
    } = useForm<FormData>();

    const [message, setMessage] = useState(
        {
            name: '',
            email: '',
            selectedProduct: selectedProduct?.title,
            messageContent: '',
            mailSent: false,
            error: null
        }
    )
    useEffect(() => {
        setMessage({ ...message, selectedProduct: selectedProduct?.title })
    }, [openModal])

    useEffect(() => {
        if (openModal === false) {
            reset(message)
            setMessage({ name: '', email: '', selectedProduct: '', messageContent: '', mailSent: false, error: null });
        } else if (openModal === true) {
            setValue("selectedProduct", selectedProduct?.title!);
            setMessage({ name: '', email: '', selectedProduct: selectedProduct?.title, messageContent: '', mailSent: false, error: null });
        }
        if (formState.isSubmitSuccessful) {
            reset(message)
            setMessage({ name: '', email: '', selectedProduct: '', messageContent: '', mailSent: false, error: null });
        }

    }, [openModal, closeModal, isSubmitSuccessful]);

    function onSubmit(data: FormData) {
        // data.preventDefault();
        try {
            setMessage({ ...message, name: data.name, email: data.email, selectedProduct: data.selectedProduct, messageContent: data.messageContent });
        }
        catch (error: any) {
            setMessage({ ...message, error: error.message, mailSent: error.message })
        }
        axios({
            method: 'post',
            url: `${API_PATH}`,
            headers: { 'content-type': 'application/json' },
            data: data
        })
            .then(result => {
                setMessage({ ...message, mailSent: result.data.sent })
            })
            .catch(error => {
                setMessage({ ...message, error: error.message, mailSent: error.message })
            }
            );

    }
    return (
        <ReactModal
            isOpen={openModal}
            className="modal"
            overlayClassName="overlay"
            ariaHideApp={false}
            closeTimeoutMS={200}
            contentLabel="modal"
            onRequestClose={closeModal}
        >
            <div className="closeIconContainer">
                <img className='closeIcon' onClick={() => setShowModal(closeModal)
                } src={closeIcon} alt="Close Icon" />
            </div>
            <div className="formContainer">
                {(selectedProduct == undefined) ?
                    <>
                        <h1><TitleComponent style={{ marginBottom: '0.5rem' }} moreSmaller medium centered>{t('contact.title')}</TitleComponent></h1>
                        <ParagraphComponent style={{ marginBottom: '2rem' }}>{t('contact.subtitle')}</ParagraphComponent>
                    </>
                    :
                    <>
                        <h1><TitleComponent style={{ marginBottom: '0.5rem' }} moreSmaller medium centered>{t('contact.titleEnquiry')}<br /><span className='enquiryProduct'>{selectedProduct?.title}</span></TitleComponent></h1><ParagraphComponent style={{ marginBottom: '2rem' }}> </ParagraphComponent>
                    </>}
                {message.mailSent && !message.error ?
                    <div className='confirmation'>
                        <img src={checkIcon} alt="Green check icon" />
                        <SubtitleComponent style={{ marginTop: '1rem', textAlign: 'center' }}>{t('contact.form.confirmation')}</SubtitleComponent>
                    </div>
                    : message.mailSent || message.error ?
                        <div className='confirmation'>
                            <img src={errorIcon} alt="Red error icon" />
                            <SubtitleComponent style={{ marginTop: '1rem', textAlign: 'center' }}>{message.error}</SubtitleComponent>
                            <ButtonComponent onClick={() => { setMessage({ name: '', email: '', selectedProduct: '', messageContent: '', mailSent: false, error: null }) }}>{t('contact.form.tryAgain')}</ButtonComponent>
                        </div>
                        : !message.mailSent && !formState.isSubmitSuccessful ?
                            <form className="emailForm" onSubmit={handleSubmit(onSubmit)} action="../../api/contact/action_page.php" method='post'>
                                <input type="text" id="name" placeholder={t('contact.form.name')} {...register("name", { required: true, pattern: /^[a-zA-Z]+(?:[' -][a-zA-Z]+)*(?:[' -][a-zA-Z]+)*$/ })} />
                                {errors?.name?.type === "required" ? (
                                    <p className="error">{t('contact.form.nameError')}</p>
                                ) :
                                    errors?.name?.type === "pattern" && (
                                        <p className="error">{t('contact.form.nameError')}</p>
                                    )}
                                <input type="text" id="email" placeholder={t('contact.form.email')} {...register("email", { required: true, pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i })} />
                                {errors?.email?.type === "required" ? (
                                    <p className="error">{t('contact.form.emailError')}</p>
                                ) :
                                    errors?.email?.type === "pattern" && (
                                        <p className="error">{t('contact.form.emailError')}</p>
                                    )}
                                <textarea style={{ height: '8rem' }} id="subject" placeholder={t('contact.form.msg')} {...register("messageContent", { required: true, minLength: 10 })}></textarea>
                                {errors?.messageContent?.type === "required" ? (
                                    <p className="error">{t('contact.form.msgError')}</p>
                                ) :
                                    errors?.messageContent?.type === "minLength" && (
                                        <p className="error">{t('contact.form.msgError')}</p>
                                    )}
                                <ParagraphComponent className={errors?.dataProtection?.type === "required" && 'error'}>
                                    <input type="checkbox" style={{ marginRight: '0.5rem' }} id="dataProtection" {...register("dataProtection", { required: true })} />
                                    <a style={{ textDecoration: 'underline' }} href="https://tesenergy.hr/assets/pdfs/privacy.pdf">{t('contact.form.dataProtection')}</a>
                                </ParagraphComponent>
                                {/* {errors?.dataProtection?.type === "required" && (
                            <p style={{ marginTop: 20 }} className="error">Ovo polje je obavezno.</p>
                        )} */}

                                {/* <ParagraphComponent className={errors?.terms?.type === "required" && 'error'} ><input type="checkbox" id="terms" {...register("terms", { required: true })} />{t('contact.form.terms')}</ParagraphComponent> */}
                                {/* {errors?.terms?.type === "required" && (
                            <p style={{ marginTop: 20 }} className="error">Ovo polje je obavezno.</p>
                        )} */}
                                <ButtonComponent style={{ marginBottom: '2rem', marginTop: '1rem' }}><input type="submit" value={t('contact.form.button')} /></ButtonComponent>
                            </form>
                            :
                            <form className="emailForm" onSubmit={handleSubmit(onSubmit)} action="../../api/contact/action_page.php" method='post'>
                                <input type="text" id="name" placeholder={t('contact.form.name')} {...register("name", { required: true, pattern: /^[a-zA-Z]+(?:[' -][a-zA-Z]+)*$/ })} />
                                {errors?.name?.type === "required" ? (
                                    <p className="error">{t('contact.form.nameError')}</p>
                                ) :
                                    errors?.name?.type === "pattern" && (
                                        <p className="error">{t('contact.form.nameError')}</p>
                                    )}
                                <input type="text" id="email" placeholder={t('contact.form.email')} {...register("email", { required: true, pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i })} />
                                {errors?.email?.type === "required" ? (
                                    <p className="error">{t('contact.form.emailError')}</p>
                                ) :
                                    errors?.email?.type === "pattern" && (
                                        <p className="error">{t('contact.form.emailError')}</p>
                                    )}

                                <ParagraphComponent className={errors?.dataProtection?.type === "required" && 'error'}>
                                    <input type="checkbox" style={{ marginRight: '0.5rem' }} id="dataProtection" {...register("dataProtection", { required: true })} />
                                    <a style={{ textDecoration: 'underline' }} href="https://tesenergy.hr/assets/pdfs/privacy.pdf">{t('contact.form.dataProtection')}</a>
                                </ParagraphComponent>
                                {/* {errors?.dataProtection?.type === "required" && (
                            <p style={{ marginTop: 20 }} className="error">Ovo polje je obavezno.</p>
                        )} */}

                                {/* <ParagraphComponent className={errors?.terms?.type === "required" && 'error'} ><input type="checkbox" id="terms" {...register("terms", { required: true })} />{t('contact.form.terms')}</ParagraphComponent> */}
                                {/* {errors?.terms?.type === "required" && (
                            <p style={{ marginTop: 20 }} className="error">Ovo polje je obavezno.</p>
                        )} */}
                                <ButtonComponent style={{ marginBottom: '2rem', marginTop: '1rem' }}><input type="submit" value={t('contact.form.button')} /></ButtonComponent>
                            </form>
                }

            </div>

        </ReactModal >
    )
}

export default ModalComponent;