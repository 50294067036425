import React from 'react'
import { ContainerComponent, ParagraphComponent, SubtitleComponent, TitleComponent } from '../../components'
import './software.style.scss'
import { Trans, withTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
//@ts-ignore
import softwarePageImage from '../../assets/images/softwarePageImage.webp'

function Software({ t }: { t: any }) {
  return (
    <div className='software'>
      <Helmet>
        <title>{t('software.meta.title')}</title>
        <meta name="description" content={t('software.meta.description')} />
      </Helmet>
      <ContainerComponent>
        <Trans>
          <div className="landingSection">
            <TitleComponent centered smaller>{t('software.title')}</TitleComponent>
            <SubtitleComponent>
              {t('software.landingSection.paragraph1')}
            </SubtitleComponent>
            <SubtitleComponent>
              {t('software.landingSection.paragraph2')}
            </SubtitleComponent>
          </div>
          <img className='software-image' src={softwarePageImage} alt={t('software.landingSection.paragraph2')} />
          <SubtitleComponent white ralewayBold>{t('software.middleSection.subtitle')}</SubtitleComponent>
          <SubtitleComponent>
            {t('software.middleSection.paragraph1')}
          </SubtitleComponent>
          <SubtitleComponent style={{ marginBottom: '2rem' }}>
            {t('software.middleSection.paragraph2')}
          </SubtitleComponent>
          <SubtitleComponent white ralewayBold>{t('software.bottomSection.subtitle')}</SubtitleComponent>
          <SubtitleComponent>
            {t('software.bottomSection.paragraph1')}
          </SubtitleComponent>
          <SubtitleComponent style={{ marginBottom: '2rem' }}>
            {t('software.bottomSection.paragraph2')}
          </SubtitleComponent>
        </Trans>
      </ContainerComponent>
    </div>
  )
}

export default withTranslation()(Software);