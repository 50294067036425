import { Helmet } from 'react-helmet';
import { withTranslation } from 'react-i18next';
import { ContainerComponent, ParagraphComponent, SubtitleComponent, TitleComponent } from '../../components';
import "./mountingSystems.style.scss"

//@ts-ignore
import tileImg from "../../assets/images/systems/tile.png";
//@ts-ignore
import adjustableImg from "../../assets/images/systems/adjustable.png";
//@ts-ignore
import ballastedImg from "../../assets/images/systems/ballasted.png";
//@ts-ignore
import carport1Img from "../../assets/images/systems/carport1.png";
//@ts-ignore
import carport2Img from "../../assets/images/systems/carport2.png";
//@ts-ignore
import concrete1Img from "../../assets/images/systems/concrete1.png";
//@ts-ignore
import concrete2Img from "../../assets/images/systems/concrete2.png";
//@ts-ignore
import concrete3Img from "../../assets/images/systems/concrete3.png";
//@ts-ignore
import ground1Img from "../../assets/images/systems/ground1.png";
//@ts-ignore
import ground2Img from "../../assets/images/systems/ground2.png";
//@ts-ignore
import metalImg from "../../assets/images/systems/metal.png";
//@ts-ignore
import railImg from "../../assets/images/systems/rail.png";
//@ts-ignore
import tilt1Img from "../../assets/images/systems/tilt1.png";
//@ts-ignore
import tilt2Img from "../../assets/images/systems/tilt2.png";
//@ts-ignore
import tilt3Img from "../../assets/images/systems/tilt3.png";
//@ts-ignore
import tripodImg from "../../assets/images/systems/tripod.png";

function MountingSystems({ t }: { t: any }) {
    return (
        <div className='mountingSystems'>
            <Helmet>
                <title>{t('mountingSystems.meta.title')}</title>
                <meta name="description" content={t('mountingSystems.meta.subtitle')} />
            </Helmet>
            <ContainerComponent>
                <h1><TitleComponent centered smaller>{t('mountingSystems.title')}</TitleComponent></h1>
                <div className="solarText">
                    <SubtitleComponent max>{t('mountingSystems.subtitle')}</SubtitleComponent>
                </div>
                <div className="systemsContainer">
                    <div className="system">
                        <div className="image">
                            <img src={metalImg} alt={t('mountingSystems.systems.1.alt')} />
                        </div>
                        <div className="description">
                            <div className="titleDiv">
                                <ParagraphComponent bold white>{t('mountingSystems.systems.1.title')}</ParagraphComponent>
                            </div>
                            <div className="subtitleDiv">
                                <ul>
                                    <li><ParagraphComponent>{t('mountingSystems.systems.1.specs.1')}</ParagraphComponent></li>
                                    <li><ParagraphComponent>{t('mountingSystems.systems.1.specs.2')}</ParagraphComponent></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="system">
                        <div className="image">
                            <img src={tileImg} />
                        </div>
                        <div className="description">
                            <div className="titleDiv">
                                <ParagraphComponent bold white>{t('mountingSystems.systems.2.title')}</ParagraphComponent>
                            </div>
                            <div className="subtitleDiv">
                                <ul>
                                    <li><ParagraphComponent>{t('mountingSystems.systems.2.specs.1')}</ParagraphComponent></li>
                                    <li><ParagraphComponent>{t('mountingSystems.systems.2.specs.2')}</ParagraphComponent></li>
                                    <li><ParagraphComponent>{t('mountingSystems.systems.2.specs.3')}</ParagraphComponent></li>
                                    <li><ParagraphComponent>{t('mountingSystems.systems.2.specs.4')}</ParagraphComponent></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <SubtitleComponent semiBold white large>{t('mountingSystems.systems.industrial')}</SubtitleComponent>
                    <div className="system">
                        <div className="image">
                            <img src={adjustableImg} />
                        </div>
                        <div className="description">
                            <div className="titleDiv">
                                <ParagraphComponent bold white>{t('mountingSystems.systems.3.title')}</ParagraphComponent>
                            </div>
                            <div className="subtitleDiv">
                                <ul>
                                    <li><ParagraphComponent>{t('mountingSystems.systems.3.specs.1')}</ParagraphComponent></li>
                                    <li><ParagraphComponent>{t('mountingSystems.systems.3.specs.2')}</ParagraphComponent></li>
                                    <li><ParagraphComponent>{t('mountingSystems.systems.3.specs.3')}</ParagraphComponent></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="system">
                        <div className="image">
                            <img src={railImg} />
                        </div>
                        <div className="description">
                            <div className="titleDiv">
                                <ParagraphComponent bold white>{t('mountingSystems.systems.4.title')}</ParagraphComponent>
                            </div>
                            <div className="subtitleDiv">
                                <ul>
                                    <li><ParagraphComponent>{t('mountingSystems.systems.4.specs.1')}</ParagraphComponent></li>
                                    <li><ParagraphComponent>{t('mountingSystems.systems.4.specs.2')}</ParagraphComponent></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="system">
                        <div className="image">
                            <img src={tripodImg} />
                        </div>
                        <div className="description">
                            <div className="titleDiv">
                                <ParagraphComponent bold white>{t('mountingSystems.systems.5.title')}</ParagraphComponent>
                            </div>
                            <div className="subtitleDiv">
                                <ul>
                                    <li><ParagraphComponent>{t('mountingSystems.systems.5.specs.1')}</ParagraphComponent></li>
                                    <li><ParagraphComponent>{t('mountingSystems.systems.5.specs.2')}</ParagraphComponent></li>
                                    <li><ParagraphComponent>{t('mountingSystems.systems.5.specs.3')}</ParagraphComponent></li>
                                    <li><ParagraphComponent>{t('mountingSystems.systems.5.specs.4')}</ParagraphComponent></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <SubtitleComponent semiBold white large>{t('mountingSystems.systems.ballasted')}</SubtitleComponent>
                    <SubtitleComponent semiBold white >{t('mountingSystems.systems.pro')}</SubtitleComponent>
                    <div className="system">
                        <div className="image">
                            <img src={tilt1Img} />
                            <img src={tilt2Img} />
                        </div>
                        <div className="description">
                            <div className="titleDiv">
                                <ParagraphComponent bold white>{t('mountingSystems.systems.6.title')}</ParagraphComponent>
                            </div>
                            <div className="subtitleDiv">
                                <ul>
                                    <li><ParagraphComponent>{t('mountingSystems.systems.6.specs.1')}</ParagraphComponent></li>
                                    <li><ParagraphComponent>{t('mountingSystems.systems.6.specs.2')}</ParagraphComponent></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="system">
                        <div className="image">
                            <img src={tilt3Img} />
                        </div>
                        <div className="description">
                            <div className="titleDiv">
                                <ParagraphComponent bold white>{t('mountingSystems.systems.7.title')}</ParagraphComponent>
                            </div>
                            <div className="subtitleDiv">
                                <ul>
                                    <li><ParagraphComponent>{t('mountingSystems.systems.7.specs.1')}</ParagraphComponent></li>
                                    <li><ParagraphComponent>{t('mountingSystems.systems.7.specs.2')}</ParagraphComponent></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <SubtitleComponent semiBold white large>{t('mountingSystems.systems.ult')}</SubtitleComponent>
                    <div className="system">
                        <div className="image">
                            <img src={ballastedImg} />
                        </div>
                        <div className="description">
                            <div className="titleDiv">
                            </div>
                            <div className="subtitleDiv">
                                <ul>
                                    <li><ParagraphComponent>{t('mountingSystems.systems.8.specs.1')}</ParagraphComponent></li>
                                    <li><ParagraphComponent>{t('mountingSystems.systems.8.specs.2')}</ParagraphComponent></li>
                                    <li><ParagraphComponent>{t('mountingSystems.systems.8.specs.3')}</ParagraphComponent></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="system">
                        <div className="image">
                            <img src={concrete1Img} />
                        </div>
                        <div className="description">
                            <div className="titleDiv">
                                <ParagraphComponent bold white>{t('mountingSystems.systems.9.title')}</ParagraphComponent>
                            </div>
                            <div className="subtitleDiv">

                            </div>
                        </div>
                    </div>
                    <div className="system">
                        <div className="image">
                            <img src={concrete2Img} />
                        </div>
                        <div className="description">
                            <div className="titleDiv">
                                <ParagraphComponent bold white>{t('mountingSystems.systems.10.title')}</ParagraphComponent>
                            </div>
                            <div className="subtitleDiv">

                            </div>
                        </div>
                    </div>
                    <div className="system">
                        <div className="image">
                            <img src={concrete3Img} />
                        </div>
                        <div className="description">
                            <div className="titleDiv">
                                <ParagraphComponent bold white>{t('mountingSystems.systems.11.title')}</ParagraphComponent>
                            </div>
                            <div className="subtitleDiv">

                            </div>
                        </div>
                    </div>
                    <SubtitleComponent semiBold white large>{t('mountingSystems.systems.ground')}</SubtitleComponent>
                    <div className="system">
                        <div className="image">
                            <img src={ground1Img} />
                        </div>
                        <div className="description">
                            <div className="titleDiv">
                                <ParagraphComponent bold white>{t('mountingSystems.systems.12.title')}</ParagraphComponent>
                            </div>
                            <div className="subtitleDiv">
                                <ul>
                                    <li><ParagraphComponent>{t('mountingSystems.systems.12.specs.1')}</ParagraphComponent></li>
                                    <li><ParagraphComponent>{t('mountingSystems.systems.12.specs.2')}</ParagraphComponent></li>
                                    <li><ParagraphComponent>{t('mountingSystems.systems.12.specs.3')}</ParagraphComponent></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="system">
                        <div className="image">
                            <img src={ground2Img} />
                        </div>
                        <div className="description">
                            <div className="titleDiv">
                                <ParagraphComponent bold white>{t('mountingSystems.systems.13.title')}</ParagraphComponent>
                            </div>
                            <div className="subtitleDiv">
                                <ul>
                                    <li><ParagraphComponent>{t('mountingSystems.systems.13.specs.1')}</ParagraphComponent></li>
                                    <li><ParagraphComponent>{t('mountingSystems.systems.13.specs.2')}</ParagraphComponent></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <SubtitleComponent semiBold white large>{t('mountingSystems.systems.carport')}</SubtitleComponent>
                    <div className="system">
                        <div className="image">
                            <img src={carport1Img} />
                        </div>
                        <div className="description">
                            <div className="titleDiv">
                            </div>
                            <div className="subtitleDiv">
                                <ul>
                                    <li><ParagraphComponent>{t('mountingSystems.systems.14.specs.1')}</ParagraphComponent></li>
                                    <li><ParagraphComponent>{t('mountingSystems.systems.14.specs.2')}</ParagraphComponent></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="system" style={{ marginBottom: "3rem" }}>
                        <div className="image">
                            <img src={carport2Img} />
                        </div>
                        <div className="description">
                            <div className="titleDiv">
                            </div>
                            <div className="subtitleDiv">
                                <ul>
                                    <li><ParagraphComponent>{t('mountingSystems.systems.15.specs.1')}</ParagraphComponent></li>
                                    <li><ParagraphComponent>{t('mountingSystems.systems.15.specs.2')}</ParagraphComponent></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </ContainerComponent>
        </div>)
}
export default withTranslation()(MountingSystems);
