import { useEffect, useState } from 'react'
import { ButtonComponent, ContainerComponent, ParagraphComponent, ProductCardComponent, ProductCardSkeletonComponent, SubtitleComponent, TitleComponent } from '../../components'
import './equipment.style.scss'
import Data from "../data";
import Categories, { CategoryEquipment } from './categories';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { decode } from 'html-entities';
import { NavLink, useMatches } from 'react-router-dom';
import { Product } from '../../components/productCard/data';
import useProductStore from '../../store/productStore';


function Equipment() {
    const menuItems: Array<string> = Array.from(new Set(Data.map((val) => val.category)));

    const { t, i18n } = useTranslation();

    let categoryButtonTextObject: CategoryEquipment = t('solarEquipment.categories')
    let categoryButtonLinkObject: CategoryEquipment = t('solarEquipment.urlCategories')

    const [tempSolarPanels, setTempSolarPanels] = useState<Product[]>([]);
    const [tempBatteries, setTempBatteries] = useState<Product[]>([]);
    const [tempInverters, setTempInverters] = useState<Product[]>([]);
    const [tempCables, setTempCabels] = useState<Product[]>([]);

    const { solarPanels, fetchSolarPanels, batteries, fetchBatteries, inverters, fetchInverters, cables, fetchCables, loading, setLoading } = useProductStore();

    useEffect(() => {
        const fetchAllProducts = async () => {
            setLoading(true)
            if (solarPanels.products.length === 0) {
                await fetchSolarPanels(0);
                // console.log("Solar Panels:", solarPanels); // Log here after fetching
            }
            if (batteries.products.length === 0) {
                await fetchBatteries(0);
                // console.log("Batteries:", batteries); // Log here after fetching
            }
            if (inverters.products.length === 0) {
                await fetchInverters(0);
                // console.log("Inverters:", inverters); // Log here after fetching
            }
            if (cables.products.length === 0) {
                await fetchCables(0);
                // console.log("Cables:", cables); // Log here after fetching
            }
            setLoading(false)
        };
       
        fetchAllProducts();
        // fetchAllProducts().then(() => {            
        //     console.log(solarPanels);
        //     console.log(batteries);
        //     console.log(inverters);
        //     console.log(cables);

        //     const sortedSolarPanels = sortProducts(solarPanels.products);
        //     setTempSolarPanels(sortedSolarPanels);
    
        //     const sortedBatteries = sortProducts(batteries.products);
        //     setTempBatteries(sortedBatteries);
    
        //     const sortedInverters = sortProducts(inverters.products);
        //     setTempInverters(sortedInverters);
    
        //     const sortedCables = sortProducts(cables.products);
        //     setTempCabels(sortedCables);
        // }).then(()=>{
        //     console.log(tempSolarPanels);
        //     console.log(tempBatteries);
        //     console.log(tempInverters);
        //     console.log(tempCables);
        // })
    }, [])

    useEffect(() => {
        const sortedSolarPanels = sortProducts(solarPanels.products);
        setTempSolarPanels(sortedSolarPanels);

        const sortedBatteries = sortProducts(batteries.products);
        setTempBatteries(sortedBatteries);

        const sortedInverters = sortProducts(inverters.products);
        setTempInverters(sortedInverters);

        const sortedCables = sortProducts(cables.products);
        setTempCabels(sortedCables);
    }, [solarPanels, batteries, inverters, cables]);

    return (
        <div className="equipment" id="equipmentContainer">
            <Helmet>
                <title>{t('solarEquipment.meta.title')}</title>
                <meta name="description" content={t('solarEquipment.meta.description')} />
            </Helmet>
            <ContainerComponent>
                <h1><TitleComponent centered smaller style={{ marginTop: '3rem' }}>{t('solarEquipment.title')}</TitleComponent></h1>
                <SubtitleComponent centered>{t('solarEquipment.subtitle')}</SubtitleComponent>
                <h3><SubtitleComponent semiBold white medium style={{ marginTop: '3rem' }}>{t('solarEquipment.categories.solar_panels')}</SubtitleComponent></h3>
                <div className="productCardContainer">
                    {loading ? (
                        <>
                            <ProductCardSkeletonComponent />
                            <ProductCardSkeletonComponent />
                            <ProductCardSkeletonComponent />
                            <ProductCardSkeletonComponent />
                        </>
                    ) : (
                        <ProductCardComponent chargers={false} data={tempSolarPanels.slice(0, 4)} />
                    )}
                </div>
                <NavLink to={t('solarEquipment.urlCategories.solar_panels')}><SubtitleComponent green centered semiBold white style={{ marginTop: '3rem' }}>{t('solarEquipment.moreProducts.solar_panels')}</SubtitleComponent></NavLink>

                <h3><SubtitleComponent semiBold white medium style={{ marginTop: '3rem' }}>{t('solarEquipment.categories.batteries')}</SubtitleComponent></h3>
                <div className="productCardContainer">
                    {loading ? (
                        <>
                            <ProductCardSkeletonComponent />
                            <ProductCardSkeletonComponent />
                            <ProductCardSkeletonComponent />
                            <ProductCardSkeletonComponent />
                        </>
                    ) : (<ProductCardComponent chargers={false} data={tempBatteries.slice(0, 4)} />)}
                </div>
                <NavLink to={t('solarEquipment.urlCategories.batteries')}><SubtitleComponent green centered semiBold white style={{ marginTop: '3rem' }}>{t('solarEquipment.moreProducts.batteries')}</SubtitleComponent></NavLink>

                <h3><SubtitleComponent semiBold white medium style={{ marginTop: '3rem' }}>{t('solarEquipment.categories.inverters')}</SubtitleComponent></h3>
                <div className="productCardContainer">
                    {loading ? (
                        <>
                            <ProductCardSkeletonComponent />
                            <ProductCardSkeletonComponent />
                            <ProductCardSkeletonComponent />
                            <ProductCardSkeletonComponent />
                        </>
                    ) : (
                        <ProductCardComponent chargers={false} data={tempInverters.slice(0, 4)} />
                    )}
                </div>
                <NavLink to={t('solarEquipment.urlCategories.inverters')}><SubtitleComponent green centered semiBold white style={{ marginTop: '3rem' }}>{t('solarEquipment.moreProducts.inverters')}</SubtitleComponent></NavLink>

                <h3><SubtitleComponent semiBold white medium style={{ marginTop: '3rem' }}>{t('solarEquipment.categories.cables_and_connectors')}</SubtitleComponent></h3>
                <div className="productCardContainer">
                    {loading ? (
                        <>
                            <ProductCardSkeletonComponent />
                            <ProductCardSkeletonComponent />
                            <ProductCardSkeletonComponent />
                            <ProductCardSkeletonComponent />
                        </>
                    ) : (
                        <ProductCardComponent chargers={false} data={tempCables.slice(0, 4)} />)}
                </div>
                <NavLink to={t('solarEquipment.urlCategories.cables_and_connectors')}><SubtitleComponent green centered semiBold white style={{ marginTop: '3rem' }}>{t('solarEquipment.moreProducts.cables_and_connectors')}</SubtitleComponent></NavLink>
            </ContainerComponent>
        </div>
    )
}

export function isImageFile(filename: string) {
    const imageExtensions = /\.(jpg|jpeg|png|webp)$/i;
    return imageExtensions.test(filename);
}

export const sortProductsByStock = (products: Product[]): Product[] => {
    return products.sort((a, b) => {
        if (a.stock === 0 && b.stock !== 0) {
            return 1;
        } else if (a.stock !== 0 && b.stock === 0) {
            return -1;
        } else {
            return 0;
        }
    });
};

export const sortProducts = (products: Product[]): Product[] => {
    return products.sort((a, b) => {
        // Check for stock
        const aInStock = a.stock && a.stock > 0;
        const bInStock = b.stock && b.stock > 0;

        // Check for image
        const aHasImage = a.image !== undefined && a.image !== null;
        const bHasImage = b.image !== undefined && b.image !== null;

        // Sort by stock first
        if (aInStock && !bInStock) return -1;
        if (!aInStock && bInStock) return 1;

        // If both have stock or neither has stock, sort by image presence
        if (aHasImage && !bHasImage) return -1;
        if (!aHasImage && bHasImage) return 1;

        // If both have stock and image or neither, keep original order
        return 0;
    })
};

export default (Equipment);

