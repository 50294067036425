import CookieConsent from 'react-cookie-consent';
import './App.scss';
import { FooterComponent, NavbarComponent } from './components';
import i18n from './i18n';
import { PathGenerator } from './pages';
import { Link, useLocation } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { changeLanguage } from 'i18next';
import useProductStore from './store/productStore';

function App({ t }: { t: any }) {
  document.documentElement.lang = i18n.resolvedLanguage;
  const location = useLocation();
  const { fetchSolarPanels, fetchBatteries, fetchCables, fetchChargerAccessories, fetchChargers, fetchInverters, inverters, chargers, solarPanels, batteries, cables, charger_accessories, loading, setLoading } = useProductStore();

  useEffect(() => {
    const pathname = new URL(window.location.href).pathname;
    const parts = pathname.split('/');
    const lang = parts[1];
    changeLanguage(lang)
  }, [location])

  useEffect(() => {
    if (!loading) {
      if (solarPanels.products.length === 0) fetchSolarPanels(0);
      if (batteries.products.length === 0) fetchBatteries(0);
      if (inverters.products.length === 0) fetchInverters(0);
      if (cables.products.length === 0) fetchCables(0);
      if (chargers.products.length === 0) fetchChargers(0);
      if (charger_accessories.products.length === 0) fetchChargerAccessories(0);
    }
  }, [])

  // useEffect(() => {
  //   const fetchAllProducts = async () => {
  //     await Promise.all([
  //       fetchSolarPanels(),
  //       fetchInverters(),
  //       fetchBatteries(),
  //       fetchCables(),
  //       fetchChargerAccessories(),
  //       fetchChargers()
  //     ]);

  //     if (chargers.length > 0) {
  //       setLoading(false);
  //     }
  //   };

  //   fetchAllProducts();
  // }, [])

  // useEffect(() => {
  //   if (chargers.length > 0) {
  //     setLoading(false)
  //   }
  // }, [loading])



  return (
    <div className="App">
      <CookieConsent disableButtonStyles buttonClasses='button button-green cookieButton' declineButtonClasses='declineCookieButton'>This website uses cookies to enhance the user experience. <a href="https://tesenergy.hr/assets/pdfs/privacy.pdf">Privacy Policy</a></CookieConsent>
      <NavbarComponent />
      <PathGenerator />
      <FooterComponent />
    </div >
  );
}

export default withTranslation()(App);
