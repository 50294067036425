import React, { Children } from 'react'

//@ts-ignore
import './typographyStyles.scss'

interface Props {
    children: React.ReactNode;
    style?: any;
    medium?: boolean;
    max?: any;
    bold?: boolean;
    white?: boolean;
    dark?: boolean;
    margins?: boolean;
    link?: boolean;
    className?: any;
    onClick?: any;
}

export default function ParagraphComponent(props: Props) {
    const { children, style, max, medium, white, bold, dark, margins, link, className, onClick } = props;

    return (
        <div onClick={onClick} className={`paragraph 
        ${white && 'paragraph-white'}
        ${max && 'paragraph-max'}
        ${medium && 'paragraph-medium'}
        ${margins && 'paragraph-margins'}
        ${dark && 'paragraph-dark'}
        ${link && 'paragraph-link'}
        ${bold && 'paragraph-bold'}
        ${className}`}
            style={style}>
            {children}
        </div>
    )
}

