import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form';
import { ButtonComponent, ParagraphComponent } from '../../components';

import './login.style.scss'

type FormData = {
  username: string;
  password: string;
};
const API_PATH = 'https://tesenergy.hr/api/login/login.php';

export default function Login() {

  const {
    register,
    handleSubmit,
    reset,
    formState,
    formState: { errors },
    formState: { isSubmitSuccessful }
  } = useForm<FormData>();

  const [login, setLogin] = useState(
    {
      username: '',
      password: '',
      loginStatus: false,
      error: null
    }
  )

  useEffect(() => {
    if (formState.isSubmitSuccessful) {
      reset({ username: '', password: '' });
    }
  }, [formState, login, reset]);

  function onSubmit(data: FormData) {
    // data.preventDefault();
    setLogin({ ...login, username: data.username, password: data.password });

    axios({
      method: 'post',
      url: `${API_PATH}`,
      headers: { 'content-type': 'application/json' },
      data: data
    })
      .then(result => {
        setLogin({ ...login, loginStatus: result.data.sent })
      })
      .catch(error => setLogin({ ...login, error: error.message }));

  }

  return (
    <div className='login'>
      <div className="formContainer">
        <form className="emailForm" onSubmit={handleSubmit(onSubmit)} action="../../api/contact/action_page.php" method='post'>
          <input type="text" id="username" placeholder="Korisničko ime" {...register("username", { required: true })} />
          <input type="password" id="password" placeholder="Zaporka" {...register("password", { required: true, pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i })} />
          {errors?.password?.type === "pattern" && (
            <p className="error">Invalid email address</p>
          )}
          <ButtonComponent style={{ marginBottom: '2rem', marginTop: '1rem' }}><input type="submit" value="Log in" /></ButtonComponent>
        </form>
        <ParagraphComponent>Register here</ParagraphComponent>
      </div>
    </div>
  )
}
