import React from 'react'
import { CardComponent, ContainerComponent, ParagraphComponent, SubtitleComponent, TitleComponent } from '../../components';
import './heatPumps.styles.scss';
import { Trans, withTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';

//@ts-ignore
import elements from '../../assets/images/elements.webp'
//@ts-ignore
import building from '../../assets/images/building.webp'
//@ts-ignore
import gree from '../../assets/images/gree.svg'
//@ts-ignore
import azuri from '../../assets/images/azuri.png'
//@ts-ignore
import mitsubishi from '../../assets/images/mitsubishi.svg'
//@ts-ignore
import ecodan from '../../assets/images/ecodan.webp'

function HeatPumps({ t }: { t: any }) {
  return (
    <div className='heatPumps'>
      <Helmet>
        <title>{t('heatPumps.meta.title')}</title>
        <meta name="description" content={t('heatPumps.meta.description')} />
      </Helmet>
      <ContainerComponent>
        <Trans>
          <h1><TitleComponent centered smaller>{t('heatPumps.title')}</TitleComponent></h1>
          <div className="imageContainer">
            <img src={building} alt={t('heatPumps.alt')} />
          </div>
          <div className="landingSection">
            <ParagraphComponent medium>{t('heatPumps.introText.1')}</ParagraphComponent>
            <ParagraphComponent medium>{t('heatPumps.introText.2')}</ParagraphComponent>
            <ParagraphComponent medium>{t('heatPumps.introText.3')}</ParagraphComponent>
          </div>
          <div className="twoItemsContainer">
            <CardComponent>
              <h3><TitleComponent centered moreSmaller>{t('heatPumps.items.1.title')}</TitleComponent></h3>
              <ParagraphComponent medium>{t('heatPumps.items.1.subtitle')}</ParagraphComponent>
            </CardComponent>
            <CardComponent>
              <h3><TitleComponent centered moreSmaller>{t('heatPumps.items.2.title')}</TitleComponent></h3>
              <ParagraphComponent medium>{t('heatPumps.items.2.subtitle')}</ParagraphComponent>
            </CardComponent>
          </div>
          <div className="types">
            <div className="water">
              <h3><SubtitleComponent large white ralewayBold>{t('heatPumps.types.1.title')}</SubtitleComponent></h3>
              <ParagraphComponent>{t('heatPumps.types.1.subtitle')}</ParagraphComponent>
            </div>
            <div className="ground">
              <h3><SubtitleComponent large white ralewayBold>{t('heatPumps.types.2.title')}</SubtitleComponent></h3>
              <ParagraphComponent>{t('heatPumps.types.2.subtitle')}</ParagraphComponent>
            </div>
            <div className="air">
              <h3><SubtitleComponent large white ralewayBold>{t('heatPumps.types.3.title')}</SubtitleComponent></h3>
              <ParagraphComponent>{t('heatPumps.types.3.subtitle')}</ParagraphComponent></div>
            <div className="image">
              <img src={elements} alt={t('heatPumps.types.alt')} />
            </div>
          </div>
          <div className="efficiency">
            <h2><SubtitleComponent centered large white ralewayBold>{t('heatPumps.coeficient.title')}</SubtitleComponent></h2>
            <ParagraphComponent>{t('heatPumps.coeficient.subtitle')}</ParagraphComponent>
          </div>
          <div className="logos">
            <img src={gree} alt={t('heatPumps.logos.gree')} />
            <img src={azuri} alt={t('heatPumps.logos.azuri')} />
            <img src={mitsubishi} alt={t('heatPumps.logos.mitsubishi')} />
          </div>
          <div className="mitsubishiSection">
            <h2><SubtitleComponent centered large white ralewayBold>{t('heatPumps.mitsubishi.title')}</SubtitleComponent></h2>
            <div className="twoItems">
              <div><h3><ParagraphComponent bold white>{t('heatPumps.mitsubishi.subtitle1')}</ParagraphComponent></h3>
                <ParagraphComponent>{t('heatPumps.mitsubishi.text1')}</ParagraphComponent></div>
              <div><h3><ParagraphComponent bold white>{t('heatPumps.mitsubishi.subtitle2')}</ParagraphComponent></h3>
                <ParagraphComponent>{t('heatPumps.mitsubishi.text2')}</ParagraphComponent></div>
            </div>
          </div>
          <div className="ecodanSection">
            <h2><SubtitleComponent centered large white ralewayBold>{t('heatPumps.ecodan.title')}</SubtitleComponent></h2>
            <div className="ecodanContent">
              <div className="text">
                <ParagraphComponent>
                  <ul>
                    <li>{t('heatPumps.ecodan.list.1')}</li>
                    <li>{t('heatPumps.ecodan.list.2')}</li>
                    <li>{t('heatPumps.ecodan.list.3')}</li>
                    <li>{t('heatPumps.ecodan.list.4')}</li>
                  </ul>
                </ParagraphComponent>
                <ParagraphComponent>
                  {t('heatPumps.ecodan.subtitle')}
                </ParagraphComponent>
              </div>
              <div className="image">
                <img src={ecodan} alt={t('heatPumps.ecodan.alt')} />
              </div>
            </div>
          </div>
          <div className="mrslimSection">
            <h2><SubtitleComponent centered large white ralewayBold>{t('heatPumps.mrslim.title')}</SubtitleComponent></h2>
            <ParagraphComponent>{t('heatPumps.mrslim.subtitle')}</ParagraphComponent>

          </div>
        </Trans>
      </ContainerComponent>
    </div>
  )
}

export default withTranslation()(HeatPumps);

