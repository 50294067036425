import { Helmet } from 'react-helmet'
import { ButtonComponent, ContainerComponent, ParagraphComponent, ProductCardComponent, ProductCardSkeletonComponent, SubtitleComponent, TitleComponent } from '../../../components'
import { Trans, useTranslation } from 'react-i18next'
import { sortProductsByStock } from '../../equipment/Equipment';
import { useEffect, useState } from 'react';
import { Product } from '../../../components/productCard/data';
import { decode } from 'html-entities';
import useProductStore from '../../../store/productStore';

function EvChargers() {
    const { t, i18n } = useTranslation();
    const { chargers, loading, fetchChargers } = useProductStore();
    const [tempChargers, setTempChargers] = useState<Product[]>(chargers.products);
    const [currentPage, setCurrentPage] = useState(1)

    useEffect(() => {
        if (chargers.products.length === 0) fetchChargers(0);
    }, [])

    useEffect(() => {
        const sortedProducts = sortProductsByStock(chargers.products);
        setTempChargers(sortedProducts);
    }, [chargers]);

    // useEffect(() => {
    //     chargers.forEach((value: any) => {
    //         let sumOfStock = 0

    //         if (value.multiprices[1] !== 0 || value['cost_price'] !== 0) {
    //             Promise.all([fetchProductStock(value.id) || {}, fetchProductImage(value.id)]).then(([stock, image]) => {
    //                 if (stock.error) {
    //                     setTempChargers(prevProducts => [...prevProducts, { id: value.id, brand: value.array_options['options_brand'], title: value.array_options['options_title'], desc: decode(value.description), url: value.url, price: Number(value.multiprices[1]) === 0 ? Number(value['cost_price']) : Number(value.multiprices[1]), image: image, category: 'chargers', stock: 0 }])
    //                 }
    //                 else if (stock) {
    //                     stock = stock['stock_warehouses']
    //                     Object.keys(stock).map((stockValue: any, index) => {
    //                         stockAvailability(stockValue).then(result => {
    //                             if (result) {
    //                                 sumOfStock = Number(stock[stockValue].real)
    //                             }
    //                             if (index == 0) {
    //                                 setTempChargers(prevProducts => [...prevProducts, { id: value.id, brand: value.array_options['options_brand'], title: value.array_options['options_title'], desc: decode(value.description), url: value.url, price: Number(value.multiprices[1]) === 0 ? Number(value['cost_price']) : Number(value.multiprices[1]), image: image, category: 'chargers', stock: sumOfStock }])
    //                             }
    //                         })

    //                     })
    //                 }
    //             }

    //             )
    //         }

    //     });

    // }, [chargers]);

    return (
        <div className='chargers'>
            <Helmet>
                <title>{t('chargersEquipment.meta.title')}</title>
                <meta name="description" content={t('chargersEquipment.meta.description')} />
            </Helmet>
            <ContainerComponent>
                <Trans>
                    <h1><TitleComponent smaller centered>{t('chargersEquipment.title')}</TitleComponent></h1>
                    <SubtitleComponent centered>{t('solarEquipment.subtitle')}</SubtitleComponent>

                    <div className="twoItemsContainer" id="chargersContainer">
                        <div className="acContainer">
                            <h2><TitleComponent centered moreSmaller>{t('chargersEquipment.acTitle')}</TitleComponent></h2>
                            <ParagraphComponent>{t('chargersEquipment.acText')}</ParagraphComponent>
                        </div>
                        <div className="dcContainer">
                            <h2><TitleComponent centered moreSmaller>{t('chargersEquipment.dcTitle')}</TitleComponent></h2>
                            <ParagraphComponent>{t('chargersEquipment.dcText')}</ParagraphComponent>
                        </div>
                    </div>
                </Trans>

                {loading ?
                    <div className="productCardContainer">
                        <ProductCardSkeletonComponent />
                        <ProductCardSkeletonComponent />
                        <ProductCardSkeletonComponent />
                        <ProductCardSkeletonComponent />
                        <ProductCardSkeletonComponent />
                        <ProductCardSkeletonComponent />
                    </div> : <>
                        {chargers.pagination.total ? <p style={{ color: 'white' }}>{tempChargers.length} of {chargers.pagination.total} Products</p> : ''}
                        <div className="productCardContainer">
                            <ProductCardComponent data={tempChargers} />
                        </div>
                    </>}
                {chargers.pagination.page_count && currentPage <= chargers.pagination.page_count - 1 ?
                    <ButtonComponent style={{margin: '0 auto', display: 'block'}} onClick={() => { setCurrentPage(currentPage + 1); fetchChargers(currentPage) }}>Load more</ButtonComponent>
                    : ''}


            </ContainerComponent>
        </div>
    )
}

export default EvChargers