import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import hr from './assets/i18n/hr.json';
import en from './assets/i18n/eng.json';

const resources = {
  hr: {
    translation: hr,
  },
  en: {
    translation: en,
  }
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    load: 'languageOnly',
    resources,
    lng: localStorage.getItem('lng'),
    returnObjects: true,
    debug: false,
    nonExplicitWhitelist: false,
    fallbackLng: 'en',
    react: {
      transKeepBasicHtmlNodesFor: ['br', 'strong', 'i', 'span','a'],
    },
    interpolation: {
      escapeValue: false,
    },

  });

export default i18n;
