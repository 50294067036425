import React, { useEffect, useState } from 'react'
import axios from 'axios';
import ReactModal from 'react-modal';
import { useForm } from "react-hook-form";
import { ParagraphComponent, SubtitleComponent, TitleComponent, ButtonComponent } from '..';
import { Trans, useTranslation } from 'react-i18next';
import { data } from '../productCard/data';

import './referenceModal.style.scss'

//@ts-ignore
import closeIcon from '../../assets/icons/closeIcon.png';
//@ts-ignore
import checkIcon from '../../assets/icons/checkIcon.svg';
//@ts-ignore
import errorIcon from '../../assets/icons/errorIcon.svg';


interface Props {
    openModal: boolean;
    closeModal: any;
    referenceContent?: any;
}


function ReferenceModalComponent(props: Props) {
    const { t, i18n } = useTranslation();
    const { openModal, closeModal } = props;
    const [showModal, setShowModal] = useState(false);
    const { referenceContent } = props;

    return (
        <ReactModal
            isOpen={openModal}
            className="modal"
            overlayClassName="overlay"
            ariaHideApp={false}
            closeTimeoutMS={200}
            contentLabel="modal"
            onRequestClose={closeModal}
        >
            <Trans>
                <div className="closeIconContainer">
                    <img className='closeIcon' onClick={() => setShowModal(closeModal)
                    } src={closeIcon} alt="Close Icon" />
                </div>
                <div className="referenceModalContainer">
                    {referenceContent}
                </div>
            </Trans>

        </ReactModal >
    )
}

export default ReferenceModalComponent;